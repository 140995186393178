.app-layout {
  min-height: 100vh;
  background: $page-background;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: $headerH;
    background-color: white;
    box-shadow: 0px 1px 5px 0px $shadow;

    .logo {
      height: $headerH;

      > a {
        .dashboard {
          font-weight: normal;
          line-height: 1;
        }

        &.active {
          .dashboard {
            font-weight: 600;
          }
        }
      }

      @extend .sidebar-widths;
    }
  }

  .main {
    position: relative;
    // top: $headerH;
    min-height: calc(100vh - #{$headerH});
    width: calc(100% - #{map-get($sidebar-breakpoints, landscape)});
    left: map-get($sidebar-breakpoints, landscape);

    @include media-breakpoint-up(lap) {
      width: calc(100% - #{map-get($sidebar-breakpoints, lap)});
      left: map-get($sidebar-breakpoints, lap);
    }

    @include media-breakpoint-up(hd) {
      width: calc(100% - #{map-get($sidebar-breakpoints, hd)});
      left: map-get($sidebar-breakpoints, hd);
    }
  }
}

body.page-background {
  background-color: $page-background;
}
