.portait-view {
  display: none;
  background: #1a1a1a;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100000;
  left: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    text-align: center;
    color: #fff;

    &.rotate-phone {
      transform: rotate(90deg);
      animation-name: anim-phone;
      animation-duration: 3s;
      animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      animation-delay: 1s;
      animation-iteration-count: infinite;
      opacity: 0;
      width: 100px;
      height: 100px;
      background-image: url('~assets/image/mobile.svg');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 2em;
    }

    &.rotate-text {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
}

@media screen and (orientation: portrait) {
  .portait-view {
    display: flex;
  }
}
