/* Colors */
$primary: #1c2a3b;

$gray-100: #f2f5f7;
$gray-300: #eaebec;
$gray-350: #d8dbe2;
$gray-400: #dfdfdf;
$gray-500: #dfe3e6;
$gray-550: #c2c4c9;
$gray-600: #b9c8d3;
$gray-650: #a6a6a6;
$gray-700: #86898e;
$gray-800: #6c6c6c;

$red-100: #ffe1e1;
$red-200: #ff9999;
$red-300: #ff8c8c;
$red-400: #fa7268;
$red-600: #cc173b;
$red-700: #a12941;

$green-500: #00b514;
$green-600: #059114;

$blue-100: #d2dfef;

$light-blue: #608fc9;
$blue: #3374d0;
$dark-blue: #15284b;

$light-red: $red-300;
$dark-red: $red-700;
$green: #129387;
$black: #1c2a3b;
$orange: #ff7d00;
$orange-500: #ed9b40;
$accoma: #fedd00;
$purple-300: #7a5ab2;
$nepal: #91a5bd;

$watermelon: #fa7268;

$brainy-green: #00d3cd;
$brainy-gray: $gray-350;

$apparatus: #15489f;

$public-text: #453f51;
$public-border: $brainy-gray;

$shadow: #d0d0d0;
$selected-option: #e5ecf6;
$page-background: #f8f8f8;
$border-color: #ededed;
$calendar-border-color: $gray-400;
$info-text: #8f8f8f;
$text-color: $primary;
$placeholder: #8c8c8c;
$gray-900: #3c4858;
$save-gray: #bab9b9;

$text-muted: #afafaf;

$booking-type-1: $dark-red;
$booking-type-2: $primary;
$booking-type-3: #6585ac;
$booking-type-4: #ba6b7a;
$booking-type-5: #59636f;
$booking-type-6: #88aad3;
$booking-type-7: #d096a1;
$booking-type-8: #7e8995;
$booking-type-9: #3da69c;
$booking-type-10: #e3b2bf;
$booking-type-11: #9da9b5;
$booking-type-12: #7dc4bd;

$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $light-blue,
    'green': $green,
    'cancel': $light-blue,
    'add': $light-blue,
    'danger': $red-400,
    'orange': $orange,
    'apparatus': $apparatus,
    'brainy': $brainy-green,
    'activate': $green-500,
    'deactivate': $watermelon,
    'orange-secondary': $orange-500,
    'rose_gold': #3da69c
  ),
  $theme-colors
);

/* Spacers */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5
    ),
    2: $spacer,
    3: (
      $spacer * 1.5
    ),
    4: (
      $spacer * 2
    ),
    5: (
      $spacer * 2.5
    ),
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 3.5
    ),
    8: (
      $spacer * 4
    ),
    9: (
      $spacer * 4.5
    ),
    10: (
      $spacer * 5
    ),
    11: (
      $spacer * 5.5
    ),
    12: (
      $spacer * 6
    ),
    13: (
      $spacer * 6.5
    ),
    14: (
      $spacer * 7
    ),
    15: (
      $spacer * 7.5
    ),
    16: (
      $spacer * 8
    ),
    17: (
      $spacer * 8.5
    ),
    18: (
      $spacer * 9
    ),
    19: (
      $spacer * 9.5
    ),
    20: (
      $spacer * 10
    ),
    21: (
      $spacer * 10.5
    ),
    26: (
      $spacer * 13
    ),
    28: (
      $spacer * 14
    )
  ),
  $spacers
);

$grid-gutter-width: 1rem;

/* Sizes */
// Set helper sizes
$size2: 0.125rem; // 2px
$size4: 0.25rem; // 4px
$size6: 0.375rem;
$size8: map-get($spacers, 1); // 8px
$size9: 0.5625rem; // 9px
$size10: 0.625rem; // 10px
$size12: 0.75rem; // 12px
$size14: 0.875rem; // 14px
$size16: map-get($spacers, 2); // 16px
$size18: 1.125rem; // 18px
$size20: 1.25rem; // 20px
$size22: 1.375rem; // 22px
$size24: map-get($spacers, 3); // 24px
$size26: 1.625rem;
$size28: 1.75rem; // 28px
$size30: 1.875rem;
$size32: map-get($spacers, 4); // 32px
$size34: 2.125rem;
$size36: 2.25rem;
$size40: map-get($spacers, 5); // 40px
$size44: 2.75rem; // 404px
$size48: map-get($spacers, 6); // 48px
$size52: 3.25rem;
$size54: 3.375rem; // 54px
$size56: map-get($spacers, 7); // 56px
$size62: 3.875rem;
$size64: map-get($spacers, 8); // 64px
$size72: map-get($spacers, 9); // 72px
$size80: map-get($spacers, 10); // 80px
$size84: 5.25rem;
$size88: map-get($spacers, 11); // 88px
$size96: map-get($spacers, 12); // 96px
$size104: map-get($spacers, 13); // 104px
$size112: map-get($spacers, 14); // 112px
$size120: map-get($spacers, 15); // 120px
$size128: map-get($spacers, 16); // 128px
$size136: map-get($spacers, 17); // 136px
$size144: map-get($spacers, 18); // 144px
$size152: map-get($spacers, 19); // 152px
$size160: map-get($spacers, 20); // 160px
$size168: map-get($spacers, 21); // 168px
$size208: map-get($spacers, 26); // 208px
$size224: map-get($spacers, 28); // 224px

$sizes: (
  8: $size8,
  9: $size9,
  10: $size10,
  12: $size12,
  14: $size14,
  16: $size16,
  18: $size18,
  20: $size20,
  22: $size22,
  24: $size24,
  26: $size26
);

$h1-font-size: $size48;
$h2-font-size: $size40;
$h3-font-size: $size24;
$h4-font-size: $size32;
$h5-font-size: $size24;
$h6-font-size: $size16;
$headings-font-weight: 300;

$border-radius-base: 3px;

$text-color: $primary;
$body-color: $text-color;
$body-bg: #fff;

$input-border-radius: 0;
$input-border-color: #979797;
$input-border-width: 0;
$input-padding-x: 0;
$input-bg: transparent;
$input-height: $size32;
$input-focus-box-shadow: none;
$input-box-shadow: none;
$input-color: $body-color;
$input-focus-border-color: $input-border-color;
$input-placeholder-color: $placeholder;
$form-group-margin-bottom: $size40;
$label-margin-bottom: $size24;

$font-family-base: 'Open Sans';
$font-family-sans-serif: 'sans-serif';
$font-weight-base: 300;

$table-border-color: $border-color;

$pagination-active-bg: $light-blue;
$pagination-focus-box-shadow: none;
$pagination-line-height: 1;

$datepicker__font-family: $font-family-base;
$datepicker__border-color: transparent;
$datepicker__header-color: $body-color;
$datepicker__border-radius: 0;

$link-hover-decoration: none;

//Media
$media-transition-seconds: 1s;

$font-size-base: $size14;

$btn-border-radius: 0;
$btn-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-font-weight: 400;
$btn-padding-y: $size8;
$btn-padding-x: $size24;
$btn-line-height: 1.385rem;

$modal-content-box-shadow-xs: none;
$modal-backdrop-opacity: 0.5;
$modal-backdrop-bg: #000;
$modal-content-border-color: transparent;
$modal-content-border-radius: 0;
$modal-md: 540px;
$modal-lg: 696px;

$dropdown-border: $border-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $dark-red;
$dropdown-link-hover-bg: none;
$dropdown-link-active-color: $dark-red;
$dropdown-link-active-bg: none;

$headerH: $size64;

$sidebar-breakpoints: (
  landscape: 16rem,
  lap: 16rem,
  hd: 18rem
);

$rightSidebar-breakpoints: (
  landscape: 16rem,
  lap: 16rem,
  hd: 20rem
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  landscape: 1024px,
  xl: 1200px,
  lap: 1366px,
  xxl: 1440px,
  hd: 1680px,
  standardhd: 1920px
);

$container-xl-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1140px,
  landscape: 1140px,
  xl: 1140px,
  lap: 1200px,
  hd: 1404px
) !default;

$container-sm-max-widths: (
  xl: 680px
) !default;
