.login-page {
  .right-section {
    .logo {
      height: 114px;
      left: -2.8rem;
      margin-top: $size8;

      > img {
        height: 100%;
        object-fit: contain;
      }

      @include media-breakpoint-up(lap) {
        height: 132px;
        left: -2.8rem;
        margin-top: $size14;
      }

      @include media-breakpoint-up(xxl) {
        left: -3.8rem;
        margin-top: $size16;
        height: 192px;
      }

      img {
        width: $size80;
        max-height: 132px;
        object-fit: contain;

        @include media-breakpoint-up(landscape) {
          width: $size80;
          max-height: 192px;
        }

        @include media-breakpoint-up(lg) {
          width: $size96;
        }

        @include media-breakpoint-up(xxl) {
          width: $size128;
        }
      }
    }
  }

  .loader-wrapper {
    .loader-inner {
      background: $brainy-green;
      background: linear-gradient(to right, $brainy-green 10%, rgba(255, 255, 255, 1) 42%);

      &::before {
        background: $brainy-green;
      }

      &:after {
        background: #fff;
      }
    }
  }

  .btn {
    height: $size40;
    line-height: 1;

    &.login-method-toggle {
      display: inline-flex;
      align-items: center;
      position: fixed;
      top: $size64;
      right: $size64;

      svg path {
        fill: $primary;
      }
    }
  }

  .basic-login-header,
  .secondary-login-header {
    font-weight: 400;
    font-size: $size24;
    margin-bottom: $size32;

    @include media-breakpoint-up(xxl) {
      margin-bottom: $size40;
      font-size: $size26;
    }
  }

  .secondary-login-header {
    color: $text-muted;
  }

  .form-group {
    margin-bottom: $size32;

    @include media-breakpoint-up(xxl) {
      margin-bottom: $size40;
    }
  }

  .form-label {
    color: $primary;
    font-weight: 700;
    margin-bottom: $size8;
    line-height: 1;

    @include media-breakpoint-up(xxl) {
      margin-bottom: $size16;
    }
  }

  .form-control {
    font-size: $size14;

    @include media-breakpoint-up(xxl) {
      font-size: $size16;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .gray-link {
    color: $text-muted;
  }

  .green-link {
    display: inline-flex;
    color: $brainy-green;
    border: none !important;
  }

  &__credentials {
    .form-group__password {
      margin-bottom: $size12;
    }

    .submit-btn-wrapper {
      padding: 8.5vh 0;
    }

    .btn-outline-apparatus {
      height: $size48;
      border-color: $apparatus;
      color: $apparatus;

      svg {
        path {
          transition: all 0.15s ease-in-out;
        }
      }

      &:hover {
        background-color: $apparatus !important;
        color: #fff !important;

        svg {
          path {
            fill: #fff;

            &:last-child {
              stroke: #fff;
            }
          }
        }
      }
    }
  }

  &__password,
  &__join-us {
    &-form,
    .submit-btn-wrapper,
    .secondary-login-header {
      padding-top: 6vh;

      @include media-breakpoint-up(xxl) {
        padding-top: 8.5vh;
      }
    }

    .checkbox {
      margin-right: $size8 !important;

      .uncheckedBox {
        border-color: $brainy-green;
      }

      .checkedBox {
        border-color: $brainy-green;
        background-color: $brainy-green;
      }
    }
  }

  &.split-layout {
    @include media-breakpoint-max-page-height(580px) {
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 588px;
    }
  }
}

.login-fade-enter {
  opacity: 0;
}
.login-fade-exit {
  opacity: 1;
}
.login-fade-enter-active {
  opacity: 1;
}
.login-fade-exit-active {
  opacity: 0;
}
.login-fade-enter-active,
.login-fade-exit-active {
  transition: opacity 400ms ease-in-out;
}
