// Classes like font-12, font-md-14, font-lg-16 etc
// Add more sizes in $sizes variable
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (font: font) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}-size: $length !important;
        }
      }
    }
  }
}
