@keyframes pulse {
  to {
    opacity: 0;
  }
}

.wrapper-404 {
  height: 100vh;

  .animated-arrow {
    animation: pulse 1.2s ease-in-out 1s alternate infinite none running;
  }

  h1,
  h2 {
    font-size: $size18;
    color: $nepal;
    text-align: center;
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 250px;
  }

  &-text {
    flex-direction: column;
    margin-top: 16vh;
  }

  .animated-arrow svg {
    width: $size32 !important;
    height: $size24 !important;
  }

  @include media-breakpoint-up(lap) {
    h1,
    h2 {
      font-size: $size28;
    }

    img {
      max-height: 300px;
    }

    .animated-arrow svg {
      width: $size48 !important;
      height: $size32 !important;
    }
  }

  @include media-breakpoint-up(hd) {
    h1,
    h2 {
      font-size: $size32;
    }

    img {
      max-height: 391px;
    }
  }
}
