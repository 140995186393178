// Define vars we'll be using
$loader-size: $size32;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 2px;
$check-color: $success;

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.validation-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;

  &.loaded {
    -webkit-animation: none;
    animation: none;
    transition: border 500ms ease-out;

    &.is-valid {
      border-color: $check-color;

      .checkmark-icon {
        display: block;
      }
    }

    &.is-invalid {
      border-color: $red;

      .error-icon {
        opacity: 1;

        .path {
          &.line {
            animation: dash 0.9s 0.35s ease-in-out forwards;
          }
        }
      }
    }
  }

  .checkmark-icon,
  .error-icon {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  .checkmark-icon {
    display: none;

    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }

  .error-icon {
    opacity: 0;

    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;

      &.line {
        stroke-dashoffset: 1000;
        // animation: dash 0.9s 0.35s ease-in-out forwards;
      }
    }
  }
}
