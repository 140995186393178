.qr-code {
  .modal-content {
    width: 22rem;
    height: 42rem;
    border: none;
    background: transparent;
  }
  .modal-body {
    background: transparent !important;
    max-height: 100% !important;
  }

  .qr-scan-section {
    background: url('~assets/svg/qrcode/bg.svg') no-repeat center;
    background-size: contain;
    min-height: 38rem;
    > div {
      min-height: 38rem;
    }
    .btn-close {
      right: $size32;
      top: $size48;
    }
  }
}

.modal.show {
  .qr-modal {
    opacity: 1;
  }
}

.qr-modal {
  opacity: 0;
  transition: opacity 600ms ease-in-out;
  transition-delay: 200ms;

  .qr-access {
    height: 13rem;
    width: 13rem;
    box-shadow: 0 10px 30px 0 rgba(55, 55, 55, 0.5);
  }

  th {
    color: $green !important;
  }

  .form-label {
    max-width: $size160 !important;
    min-width: $size160 !important;
  }

  form {
    background: $gray-300;
  }
  .loader-wrapper {
    left: calc(50% - #{$size24});
    top: calc(55% - #{$size40});
  }
  #apparatus_qrcode,
  #apparatus_qrcode img {
    height: 11rem !important;
    width: 11rem !important ;
  }

  .scanned {
    position: relative;

    &:after {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      top: 0;
      left: 0;
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
.primary-message {
  font-weight: 600;
  font-size: $size22;
  max-width: 80%;
  margin: auto;
}
.secondary-message {
  font-size: $size18;
  max-width: 80%;
  margin: auto;
}
