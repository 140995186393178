.react-select {
  background-color: transparent;

  &.react-select--times {
    .react-select__control {
      min-height: $size40;
      border: 1px solid $border-color !important;
      background: #fff;
      width: $size112;

      .react-select__value-container {
        padding: 0 $size16;
        justify-content: center;
      }

      .react-select__placeholder {
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    .react-select__menu {
      .react-select__option {
        text-align: center;
      }
    }

    .react-select__indicators {
      display: none;
    }
  }

  .react-select__control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $input-border-color;
    box-shadow: none !important;
    border-radius: 0;
    min-height: $size32;

    &:hover,
    &.react-select__control--is-focused {
      border-color: $input-border-color;
    }

    .react-select__input {
      input {
        color: $input-color;
        font-weight: 300;
      }
    }

    .react-select__value-container {
      padding: 0;

      .selected-indicator {
        display: none;
      }

      .react-select__multi-value-custom-container {
        margin: 2px;

        .react-select__multi-value {
          margin: 0;
        }

        &.is-colored {
          .react-select__multi-value {
            background-color: transparent;

            .react-select__multi-value__label {
              background-color: inherit;
              font-weight: bold;
              font-size: $size10 !important;
              color: #fff;
              padding: 0 $size8;
              border-radius: 1px;
              line-height: 17px;
              text-transform: uppercase;
            }
          }

          .react-select__multi-value__remove {
            background: #fff !important;
            border-radius: 0;
          }
        }
      }

      .is-over-max {
        .selected-indicator {
          display: block;
        }

        .react-select__multi-value {
          display: none;
        }

        + .is-over-max {
          display: none;
        }
      }

      .react-select__multi-value {
        background-color: $border-color;

        .react-select__multi-value__remove {
          background: none !important;
          cursor: pointer;

          &:hover {
            color: $dark-red;
          }
        }
      }
    }

    .react-select__single-value {
      color: $input-color;
      font-size: $size14;
      top: 48%;
    }

    .react-select__placeholder {
      color: $input-placeholder-color;
      font-size: $size14;
      line-height: 1;
      margin-left: 0;
      left: 2px;
    }
  }

  .menu-is-open {
    .react-select__dropdown-indicator:after {
      transform: rotate(180deg);
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    padding: 2px;
    margin-right: $size8;

    svg {
      height: $size16;
    }
  }

  .react-select__dropdown-indicator {
    padding: 0;
    &:after {
      content: '';
      transition: 0.3s all;
      width: 10px;
      height: 10px;
      background: url('~assets/svg/general/calendar_arrow.svg') no-repeat center;
      background-size: contain;
    }

    svg {
      display: none;
    }
  }

  .react-select__menu {
    box-shadow: none;
    border-radius: 1px;
    border: 1px solid $border-color;
    margin-top: $size4;
    z-index: 5;

    .react-select__menu-list {
      padding: $size8 $size16;
      @extend.scrollbar;

      &.react-select__menu-list--is-multi {
        padding: 0;

        .react-select__option {
          padding: $size4 $size16;

          &.react-select__option--is-selected {
            background-color: $selected-option !important;

            &:not(:hover) {
              color: $body-color !important;
            }
          }
        }
      }
    }

    .react-select__menu-notice {
      color: $input-placeholder-color;
      line-height: 1;
    }

    .react-select__option {
      color: $input-color;
      background-color: transparent !important;
      padding: $size4 0;
      cursor: pointer;

      &.react-select__option--is-selected {
        color: $black;
      }
      &.react-select__option--is-focused {
        color: $light-blue;
      }
    }
  }
  .react-select__multi-value-department-container {
    .react-select__multi-value {
      background: transparent !important;
    }
  }
}
.options-dropdown {
  .dropdown-menu {
    min-width: 16rem;
    max-width: 16rem;
    top: -$size4;
    left: $size20;
    .dropdown-item {
      color: $primary;
      &:hover {
        color: $red-700;
      }
      &.success {
        &:hover {
          color: $green-500;
        }
      }
      &.danger {
        &:hover {
          color: $light-red;
        }
      }
    }
  }
}

.select-on-top {
  .react-select__menu {
    z-index: 1;
  }
}
