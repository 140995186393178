.notifications-wrapper {
  .notification-entrance {
    width: 100%;
    height: $size80;
    border-top: 1px solid;
    border-bottom: 1px solid;

    &.notification-entrance-added {
      border-color: $green;

      .btn-link {
        color: $green;
      }
    }

    &.notification-entrance-removed {
      border-color: $red-600;

      .btn-link {
        color: $red-600;
      }
    }

    .btn-link {
      position: absolute;
      top: 4px;
      right: 8px;
      width: 16px;
      height: 24px;
      background: #fff;

      &::before {
        content: '\D7';
        font-size: $size24;
        font-weight: 600;
        line-height: 1;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .notification-entrance--image {
      img {
        object-fit: cover;
      }
    }

    .notification-entrance--info {
      flex: 1;
      background: #fff;
      z-index: 1;

      .notification-entrance--info-type {
        min-width: $size8;
        height: $size8;
        border-radius: 100%;
      }
    }
  }

  .notification-info-main {
    border-top: 1px solid $light-blue;
    border-bottom: 1px solid $light-blue;
    height: 100%;
    width: 100%;

    .notification-info-main--image {
      &.notification-info-main--image-email {
        width: $size32;
        height: $size24;
        background: url('~assets/svg/general/email.svg') no-repeat center;
        transform: rotate(-20deg);
      }
    }
  }
}

.fancy-alert {
  position: fixed;
  top: 0;
  left: 20rem;
  transform: rotate3d(1, 0, 0, 0deg) translateX(-50%);
  margin: auto;
  border-radius: 0 0 5px 5px;
  z-index: 10000;
  min-width: auto;
  width: 28.75rem;
  padding: $size8 $size40;
  text-align: center;
  min-height: 48px;
  transition: 0.8s all;
  transform-origin: top;
  opacity: 1;
  font-size: $size12;

  @include media-breakpoint-up(hd) {
    min-width: 600px;
    padding: $size8 $size48;
    left: 50%;
    font-size: $size14;
  }

  &.hide {
    transform: rotate3d(1, 0, 0, 90deg) translateX(-50%);
    opacity: 0;
  }

  .close {
    position: absolute;
    top: $size12;
    right: $size16;
    opacity: 1;
  }

  &.severity-1 {
    color: $orange;
    background-color: rgba($orange, 0.3);

    svg {
      fill: $orange;
    }
  }

  &.severity-2 {
    color: $purple-300;
    background-color: rgba($purple-300, 0.3);

    svg {
      fill: $purple-300;
    }
  }

  &.severity-3 {
    color: $watermelon;
    background-color: #fdd4d1;

    svg {
      fill: $watermelon;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.page-saving-loader {
  position: fixed;
  background-color: $orange-500;
  color: $white;
  padding: $size4 $size20;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: $size12;
  font-weight: $font-weight-normal;
  height: $size24;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 16px;
  border-radius: 0 0 3px 3px;
  top: $size64;
  left: 50%;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  transition-delay: 1s;
  opacity: 0;
  transform: translateY(-28px);

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    letter-spacing: 1px;

    &:first-child {
      padding-left: 1px;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  &.is-saving {
    opacity: 1;
    transition: all 0.5s ease-in-out;
    transition-delay: 0;
    transform: translateY(0);
  }
}
