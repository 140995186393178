.hr-calendar {
  .loading {
    bottom: unset;
    top: 12px;
    height: 28px;

    .loading-bar {
      background: $primary;
      height: 6px;
    }
  }

  .popover-extra-bottom-spacing {
    top: -60px !important;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-timeline {
    .fc-head {
      .fc-time-area {
        .fc-widget-header {
          border-style: dashed;

          .fc-cell-content {
            padding: 2px;
            font-size: $size10;

            .fc-cell-text:first-line {
              font-weight: 600;
            }
          }
        }
      }
    }

    .fc-body {
      .fc-resource-area {
        .fc-cell-content {
          padding: $size8 $size4 $size8 $size24;
          height: calc(100% - #{$size40});
          min-height: $size40;
          align-items: flex-start;

          .fc-cell-text {
            flex: 1;
            line-height: 19px;
          }

          .shift-duration {
            font-weight: 400;

            .shift-duration--entrance,
            .shift-duration--total {
              color: $info-text;
            }
          }
        }

        tr[data-resource-id] {
          .fc-cell-content {
            height: calc(100% - #{$size40});
            min-height: $size40;
          }

          &:last-child {
            > td {
              border-color: #fff;
            }
          }

          .fc-cell-text:hover {
            &:first-line {
              color: $light-blue !important;
            }
          }
        }

        tr:not([data-resource-id]) {
          .fc-cell-content {
            padding: $size4 $size24;
            height: calc(100% - #{$size20});
            min-height: $size20;
            display: inline-flex;
            align-items: center;

            .fc-cell-text {
              font-size: $size10;
              margin-left: $size24;
              text-transform: uppercase;
              padding: 0;

              &::first-line {
                font-size: $size10;
              }

              text-decoration: none;
              line-height: $size14;
              font-weight: 700;
            }
          }
        }
      }

      .fc-event-container {
        min-height: $size56;
      }

      .fc-event {
        height: $size48;
        margin-top: 5px;
        margin-bottom: $size4;
        border-color: currentColor;
        padding: 0;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: currentColor;
          opacity: 0.25;
        }

        &.fc-timeline-event {
          margin-right: 0;

          .fc-content {
            flex: 1;
            padding: 0;
            display: flex;
            flex-direction: column;
            font-weight: 600;
          }

          .fc-title {
            font-size: $size10;
            padding-left: $size8;
            line-height: $size14;
            order: 1;
            font-weight: inherit;
          }

          .fc-time {
            order: 2;
            margin-top: auto;
            padding-top: $size4;
            padding-left: $size8;
            font-weight: inherit;
          }

          &.fc-not-start .fc-title:before {
            display: none;
          }

          &.fc-not-start .fc-content:before,
          &.fc-not-end .fc-content:after {
            width: 6px;
          }
        }

        &.event-transaction {
          border: none;
          background: none;
          display: inline-flex;
          align-items: center;
          height: 0;
          top: calc(50% - #{$size2}) !important;
          margin: 0;

          &::before {
            display: none;
          }

          .fc-content {
            height: $size4;
            background-color: #2e4e74;
            background: linear-gradient(180deg, #99c2e6 0%, #2e4e74 100%);
          }

          .fc-time {
            display: none;
          }
        }

        &.event-shift-leaves,
        &.event-transaction {
          &.fc-timeline-event {
            border-color: $info-text;
            color: $info-text;
          }
        }

        &.event-shift-leaves {
          align-items: center;
          border-width: 0px !important;
          display: flex;
          margin-left: 1px;

          .fc-time,
          .fc-title {
            display: none;
          }
        }

        &.event-shifts-monthly:not(.event-transaction) {
          margin: 4px;

          &.event-shifts-monthly--overlap {
            height: $size20;

            .fc-time {
              overflow: hidden;
            }
          }
        }

        &.event-shifts-monthly.event-transaction {
          top: 28px !important;
        }

        &.event-leaves-monthly {
          margin: 4px 4px 0px 4px;
        }

        &.event-transaction {
          margin: 0 4px;
          top: 50% !important;

          &.overflow-transaction {
            top: calc(50% + #{$size12}) !important;
          }
        }
      }

      .completed-shifts {
        .shift-duration--entrance {
          color: $green-500 !important;
          font-weight: 600;
        }

        &.fc-timeline-event {
          border-color: $green-500;
        }
      }

      .not-completed-shifts {
        .shift-duration--entrance {
          color: $red-400 !important;
          font-weight: 600;
        }

        &.fc-timeline-event {
          border-color: $red-400;
        }
      }
    }

    &.fc-resourceTimelineWeek-view {
      .fc-head {
        .fc-time-area {
          .fc-content > table > tbody {
            > tr {
              .fc-cell-content {
                font-weight: 600;

                .fc-cell-text {
                  padding: 0;
                }
              }

              &:first-child {
                .fc-cell-content {
                  font-size: $size14;

                  .fc-cell-text:first-line {
                    font-weight: 300;
                  }
                }
              }
            }
          }
        }
      }

      .fc-timeline-event {
        .fc-content {
          justify-content: center;

          .fc-title {
            display: none;
          }

          .fc-time {
            display: block;
            font-size: $size10;
            margin: $size4 0;
            padding-top: 0;
            padding-left: $size6;
            line-height: 19px;
          }
        }
      }
    }
  }

  .fc-bg {
    .fc-slats {
      td {
        border-style: dashed;
      }
    }
  }

  .fc-resourceTimelineDay-view {
    .fc-nonbusiness.fc-bgevent,
    .closed-day {
      margin-left: 3px;
    }
  }

  .fc-resourceTimelineWeek-view {
    .fc-nonbusiness.fc-bgevent,
    .closed-day {
      margin-left: 1px;
    }
  }

  .table-mm--search {
    + div {
      margin-bottom: 0 !important;

      .pagination-previous,
      .pagination-next,
      .pagination-current {
        display: none;
      }
    }
  }
  .head-title-left {
    right: $size4;
    bottom: $size4;
    white-space: normal;
    &.person {
      width: 2.2rem;
    }
  }
}

.icon-hidden {
  .SingleDatePicker {
    &::before {
      display: none;
    }
  }
}
