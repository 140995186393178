.inline-datepicker {
  border: 1px solid $gray-400;
  background: $white;
  padding: $size16 $size48;
  .navigation-button {
    outline: none !important;
    border: none;
    width: $size24;
    height: $size32;
    background: url('~assets/svg/general/calendar_arrow.svg') no-repeat center;
    background-origin: content-box;
    padding: 6px;
    transform: rotate(90deg);
    &.next {
      transform: rotate(-90deg);
    }
  }

  .header-text {
    font-weight: 600;
  }

  .months-container {
    overflow: hidden;
    .month-list {
      width: 300%;
      position: relative;
      left: -100%;
    }

    .moving-left {
      transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      left: -200%;
    }
    .moving-right {
      transition: all 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
      left: 0%;
    }
    .inline-datepicker-body {
      width: 100%;
    }
  }

  .cell {
    height: $size40;
    line-height: $size40;
    cursor: pointer;
    &:hover span {
      background: $gray-100;
    }

    span {
      height: $size40;
      width: 100%;
      line-height: $size40;
      display: inline-block;
    }
    &.disabled-date {
      color: $info-text;
      &.exist {
        span {
          background: $gray-400;
        }
      }
    }

    &.exist {
      span {
        height: $size24;
        width: $size24;
        line-height: $size24;
        border-radius: 12px;
        background-color: $green;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
        display: inline-block;

        padding-right: 1px;
        color: $white;
      }
    }
  }

  .days {
    .col {
      height: $size32;
      line-height: $size32;
      font-weight: 600;
      color: $info-text;
    }
  }
  .nav-section {
    padding-top: $size40;
  }
}

.inline-datepicker-transparent-bg {
  background-color: inherit;
  padding: $size16 0;
}
