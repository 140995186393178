.meeting-agenda {
  .load-button {
    &:before {
      content: none !important;
    }
  }
}

.extensive-agenda {
  .section-header {
    padding-left: 0;
    padding-right: $size10;
    font-weight: 600;
  }
}
