.intro-padding {
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(sm) {
    padding-top: $size32;
  }

  @include media-breakpoint-up(xl) {
    padding-left: $size64;
    padding-right: $size64;
  }

  @include media-breakpoint-up(hd) {
    padding-left: $size120;
    padding-right: $size120;
  }
}

.container-padding {
  max-width: 100%;
  padding: 0px 0.5rem;

  @include media-breakpoint-up(sm) {
    padding: 0px 50px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 640px;
    padding: 0;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1140px;
    padding: 0;
  }
}

.brainy-page {
  background: #fff;
  font-family: 'Muli', sans-serif;

  &.fixed-height {
    min-height: 100vh;
    height: 100vh;
  }

  [class*='border'] {
    border-color: $public-border !important;
  }

  .brainy-inner-intro {
    z-index: 1;
    .blub-title {
      z-index: -1;
      position: absolute;
      top: -$size16;
      left: -$size44;
      width: $size72;

      @include media-breakpoint-up(xl) {
        top: -$size32;
        left: -4.2rem;
        width: $size112;
      }
    }

    h1 {
      font-size: $size26;
      line-height: $size40;
      font-weight: 400;

      @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
        font-size: $size32;
        margin-top: 0;
        width: 100%;
        margin-left: 0;
      }

      @include media-breakpoint-up(sm) {
        font-size: $size32;
        margin-top: 0;
        width: 100%;
        margin-left: 0;
      }

      @include media-breakpoint-up(xl) {
        line-height: 67px;
        font-size: $size54;
        width: 155%;
      }
    }
  }

  &.landing-page {
    position: relative;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;

    .legal-link {
      .bullet {
        svg {
          > g > g {
            fill: $brainy-green;
          }
        }

        &:first-child {
          transform: rotate(40deg);
        }

        &:last-child {
          transform: rotate(-40deg);
        }
      }
    }

    .basic-right-green {
      position: absolute;
      z-index: 3;
      left: 10px;
      top: 9rem;
      transform: rotate(30deg);
      width: $size96;
    }

    .intro-section {
      min-height: 750px;
      position: relative;
      z-index: 2;

      @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
        min-height: 600px;
      }

      @include media-breakpoint-up(xl) {
        min-height: 846px;
      }

      .blub-title {
        z-index: -1;
        position: absolute;
        top: -$size16;
        left: -$size44;
        width: $size72;
        @include media-breakpoint-up(xl) {
          top: -$size32;
          left: -4.2rem;
          width: $size112;
        }
      }

      .intro-text {
        h1 {
          width: 165px;
          margin-left: $size32;
          font-size: $size26;
          line-height: $size40;
          font-weight: 400;

          margin-top: 10rem;
          @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
            font-size: $size32;
            margin-top: 0;
            width: 100%;
            margin-left: 0;
          }

          @include media-breakpoint-up(sm) {
            font-size: $size32;
            margin-top: 0;
            width: 100%;
            margin-left: 0;
          }

          @include media-breakpoint-up(xl) {
            line-height: 67px;
            font-size: $size54;
            width: 155%;
          }
        }

        h3 {
          font-size: $size14;
          line-height: $size28;
          width: 280px;
          font-weight: 400;
          @include media-breakpoint-up(sm) {
            font-size: $size16;
            width: 100%;
          }
          @include media-breakpoint-up(xl) {
            line-height: $size36;
            font-size: $size22;
          }
        }

        .intro-small-bubble {
          @include media-breakpoint-up(lg) {
            margin-top: 10.25rem;
          }

          @include media-breakpoint-up(xl) {
            margin-top: 12.5rem;
          }

          @include media-breakpoint-up(hd) {
            margin-top: 15.5rem;
          }
        }

        .with-stuff {
          height: 500px;
          width: 500px;
          object-fit: contain;
          position: absolute;
          right: -11.5rem;
          z-index: 0;
          margin: 0 auto;
          top: 0;

          @include media-breakpoint-up(sm) {
            position: initial;
            height: 435px;
            width: 500px;
            top: 6rem;
          }

          @include media-breakpoint-up(lg) {
            width: 625px;
            height: 625px;
            right: -$size32;
            top: -$size32;
            position: absolute;
          }

          @include media-breakpoint-up(xl) {
            width: 725px;
            height: 725px;
          }

          @include media-breakpoint-up(hd) {
            width: 825px;
            height: 825px;
            right: -$size32;
          }
        }
      }

      .intro-secondary-text {
        margin-top: 12.5rem;

        @include media-breakpoint-up(lg) {
          margin-top: 21.5rem;
        }

        @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
          margin-top: 6rem;
        }

        h4 {
          font-size: $size16;
          line-height: $size32;
          font-weight: 400;

          @include media-breakpoint-up(sm) {
            font-size: $size20;
          }

          @include media-breakpoint-up(xl) {
            font-size: $size28;
            line-height: $size40;
          }
        }
      }

      .blub-info-footer {
        position: absolute;
        width: $size80;
        left: 28.5%;
        bottom: 9rem;
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }

        @include media-breakpoint-up(landscape) {
          bottom: 10rem;
        }

        @include media-breakpoint-up(xl) {
          bottom: 8rem;
        }

        @include media-breakpoint-up(lap) {
          bottom: 3rem;
          width: $size144;
        }

        @include media-breakpoint-up(hd) {
          bottom: 10rem;
        }
      }
    }

    .basic-feature {
      position: relative;

      .feature-box {
        background: $red-200;
        border-radius: 0px;
        position: relative;
        z-index: 1;

        .feature-grid {
          position: relative;
          max-width: 350px;
          min-height: 355px;

          h4 {
            font-weight: 900;
            line-height: $size28;
            font-size: $size18;
            @include media-breakpoint-up(sm) {
              font-size: $size22;
            }
            @include media-breakpoint-up(landscape) {
              font-size: $size20;
            }
          }

          p {
            font-size: $size16;
            line-height: $size20;
            @include media-breakpoint-up(sm) {
              font-size: $size18;
              line-height: 23px;
            }
          }
          .bullet {
            height: $size32;
          }
          .rotate-30 {
            transform: rotate(-30deg);
          }
          .rotate-60 {
            transform: rotate(-60deg);
          }

          .rotate-90 {
            transform: rotate(-120deg);
          }

          &:first-child {
            &:after {
              display: none;

              @include media-breakpoint-up(lg) {
                display: block;
              }
            }
          }

          &:last-child {
            &:after {
              display: block;

              @include media-breakpoint-up(lg) {
                display: none;
              }
            }
          }

          &:after {
            content: '';
            border: 1px solid $red-100;
            opacity: 0.3;
            position: absolute;
            height: 2px;
            width: calc(100% - 7rem);
            right: 3.5rem;
            top: -$size56;

            @include media-breakpoint-up(lg) {
              height: calc(100% - #{$size104});
              width: 1px;
              top: $size32;
              right: 0;
            }
          }

          @include media-breakpoint-up(xl) {
            max-width: 100%;
          }
        }

        @include media-breakpoint-up(lg) {
          border-radius: 15px;
        }
      }

      .basic-left {
        position: absolute;
        z-index: 1;
        max-width: $size128;
        bottom: -$size96;
        left: -$size48;

        @include media-breakpoint-up(sm) {
          max-width: 13rem;
          bottom: -11rem;
          left: -$size64;
        }

        @include media-breakpoint-up(xl) {
          bottom: -33%;
          left: -7rem;
          max-width: 22rem;
        }
      }
      .basic-right {
        position: absolute;
        z-index: 10;
        right: -$size16;
        bottom: -$size64;
        width: $size112;
        opacity: 0.5;

        @include media-breakpoint-up(sm) {
          right: 0;
          bottom: -$size64;
          width: $size128;
        }
      }

      .basic-right-green {
        width: $size80;
        right: 0;
        left: initial;
        position: absolute;
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
          top: $size20;
          right: $size32;
        }

        @include media-breakpoint-up(landscape) {
          top: 8rem;
        }

        @include media-breakpoint-up(xl) {
          width: 9rem;
          right: 0;
          top: 29%;
          z-index: 0;
        }

        @media only screen and (max-width: map-get($grid-breakpoints, landscape)) and (min-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
          z-index: 2;
          top: 7rem;
        }
      }

      .container {
        max-width: 100%;

        @include media-breakpoint-up(lg) {
          max-width: 906px;
        }

        @include media-breakpoint-up(xl) {
          max-width: 1140px;
        }
      }
    }
    .basic-logo {
      position: absolute;
      left: $size16;
      bottom: 0;
    }

    .feature-title {
      position: relative;
      h5 {
        font-size: $size20;
        color: $public-text;
        padding: $size40 $size48;
        font-weight: 900;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        background: url('~assets/svg/public/blubs/pattern_pink.svg') repeat;
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }

  .logo-container {
    .intro-logo-small {
      .svg-wrap {
        z-index: 15;
      }

      top: $size16;
      margin-bottom: -$size64;
    }

    .intro-logo {
      position: fixed;
      top: $size24;
      left: $size24;
      z-index: 100;

      svg.logo {
        height: 325px;
        width: 216px;
      }

      .logo-text {
        @extend .d-flex;
        svg {
          color: $public-text;
        }
        .left {
          margin-right: 6px;
        }
        .right {
          margin-left: 0;
          @include media-breakpoint-up(hd) {
            margin-left: 5px;
          }
        }
      }

      transition: all 0.6s;
      transform-origin: left top;

      width: 115px;
      height: 174px;

      @include media-breakpoint-up(hd) {
        left: $size40;
        top: $size40;
        width: 216px;
        height: 325px;
      }
      &.active {
        width: 80px;
        height: 110px;
        @include media-breakpoint-up(xl) {
          left: 1.25rem;
        }
        @include media-breakpoint-up(hd) {
          left: 1.75rem;
          width: 115px;
          height: 158px;
        }
        .left {
          margin-right: 0px;
          svg {
            transform-origin: center left;
            transform: translate(100%, 0);
          }
        }

        .right {
          margin-left: 0px;
          svg {
            transform-origin: center right;
            transform: translate(-100%, 0);
          }
        }
      }

      .svg-conatiner {
        overflow: hidden;

        svg {
          transition: all 0.6s;
          transform: translate(0, 0);
          @extend .w-100;
        }
      }
    }
  }

  .plugin {
    .slider-plugin-wrapper {
      .slick-list {
        width: 100%;
      }
    }

    .slick-current {
      margin-top: $size20;
      transition: all 600ms ease-in-out 0s;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    .slick-slide .plugin-col img {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      padding-left: $size56;
      padding-right: $size56;
    }

    @include media-breakpoint-up(hd) {
      padding-left: $size80;
      padding-right: $size80;
    }

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }
    .plugin-text {
      min-height: $size40;
      z-index: 0;

      h4 {
        font-size: $size40;
        font-weight: 900;
        color: $gray-350;
      }
    }
    h5 {
      color: $gray-350;
      font-size: $size16;
      font-weight: bold;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        font-size: $size20;
      }
      @include media-breakpoint-up(xl) {
        font-size: $size22;
      }
    }

    p {
      font-weight: 400;
      font-size: $size14;
      line-height: 23px;
      margin-top: $size16;
      margin-bottom: 0;

      @include media-breakpoint-up(sm) {
        font-size: $size18;
        line-height: 37px;
      }
    }

    .plugin-blub {
      position: absolute;
      right: -4rem;
      border: 0;

      width: 15rem;
      bottom: -8rem;
    }

    .plugin-tab {
      z-index: 0;
      display: none;

      &.active {
        display: block;
      }

      @include media-breakpoint-up(md) {
        display: block;
      }
      .blub {
        z-index: -1;
        position: absolute;
        top: 0px;
        left: 40%;
        color: $red-200;
        opacity: 0;
      }

      &.active,
      &:hover {
        h5 {
          color: $public-text;
        }

        .blub {
          opacity: 1;
        }
      }
    }

    .plugin-paragraph {
      color: $public-text;
      font-size: $size14;
      line-height: $size20;

      @include media-breakpoint-up(md) {
        font-size: $size16;
        line-height: $size24;
      }

      @include media-breakpoint-up(xl) {
        font-size: $size18;
        line-height: $size28;
      }

      ul {
        padding-left: 0;
        margin-top: $size4;

        @include media-breakpoint-up(xl) {
          padding-left: $size40;
          margin-top: $size8;
        }

        & > li {
          margin-bottom: $size4;
          list-style-type: none;

          @include media-breakpoint-up(xl) {
            list-style-type: initial;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    #tail {
      width: 30rem;
      right: calc(50% - 96px);
      position: absolute;
      bottom: -$size12;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        width: 35rem;
        right: calc(50% - 108px);
      }
      @include media-breakpoint-up(xl) {
        bottom: -$size12;
      }
    }

    .slider-plugin-wrapper {
      position: relative;
      z-index: 1;

      .feature-title {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        @include media-breakpoint-up(lg) {
          border-radius: 15px;
        }
      }

      .plugin-col {
        p {
          @include media-breakpoint-up(sm) {
            max-width: 70%;
          }
          @include media-breakpoint-up(xl) {
            min-height: auto;
            max-width: 100%;
          }
        }
      }

      .box-center {
        border-radius: 15px;
        width: calc(100% - 1rem) !important;
        height: 100%;
        margin-left: 0.5rem;
        box-shadow: 0 2px 9px 0 #d8dbe2;

        @include media-breakpoint-up(lg) {
          box-shadow: none;
          border-radius: 0px;
          width: 100%;
        }
      }

      .slick-list {
        @extend .d-flex;

        .slick-track {
          @extend .d-flex;
          padding-bottom: $size32;

          @include media-breakpoint-up(xl) {
            padding-bottom: 0;
          }
        }

        .slick-slide {
          height: 100%;
          float: none;
          @extend .d-flex;
          opacity: 0.7;
          transition: all 0.5s;

          @include media-breakpoint-up(lg) {
            opacity: 1;
          }

          & > div > div {
            outline: none !important;
          }

          &.slick-center {
            opacity: 1;
          }
        }
      }
    }
  }
  #right-blub,
  #right-blub-big {
    display: none;
    z-index: 1;
    @include media-breakpoint-up(xl) {
      display: block;
      height: 8rem;
      bottom: 18rem;
      right: 3rem;
    }
    @include media-breakpoint-up(xxl) {
      bottom: 17rem;
      right: 4rem;
    }

    @include media-breakpoint-up(hd) {
      right: 5rem;
    }
  }

  #right-blub-big {
    height: 15rem;
    width: 15.5rem;
    bottom: 20rem;
    right: 14rem;
  }

  .notification-section {
    margin-top: $size20;

    @include media-breakpoint-up(lg) {
      margin-top: 5rem;
    }

    .feature-title {
      background: $red-200;
      padding: $size24;
      border-radius: 15px;
      height: 100%;

      min-height: 808px;

      @include media-breakpoint-up(md) {
        min-height: 478px;
      }

      @include media-breakpoint-up(xl) {
        min-height: 314px;
      }

      @media only screen and (max-width: map-get($grid-breakpoints, landscape)) and (orientation: landscape) {
        min-height: initial;
      }

      > div {
        > div {
          padding: 0 $size16;

          @include media-breakpoint-up(lg) {
            padding: 0 $size24;
          }

          @include media-breakpoint-up(hd) {
            padding: 0 $size56;
          }
        }
      }

      p {
        font-weight: bold;
        font-size: $size14;
        line-height: $size20;

        @include media-breakpoint-up(sm) {
          font-size: $size16;
        }

        @media only screen and (max-width: map-get($grid-breakpoints, landscape)) and (min-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
          font-size: $size12;
        }
      }

      .notification {
        height: $size64;
      }
      @include media-breakpoint-up(xl) {
        padding: $size80 $size24;
      }
    }
  }

  .getstarted-section {
    z-index: 1;
    margin-top: $size80;

    @include media-breakpoint-up(md) {
      margin-top: 148px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 220px;
    }

    .getstarted {
      background: $gray-350;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      h3 {
        font-size: $size16;
        line-height: $size24;
        font-weight: $font-weight-bold;
        margin: 0 !important;

        @include media-breakpoint-up(md) {
          font-size: $size18;
        }

        @include media-breakpoint-up(xl) {
          font-size: $size26;
        }

        @media only screen and (max-width: map-get($grid-breakpoints, xl)) and (orientation: landscape) {
          font-size: $size20;
        }
      }

      h4 {
        font-size: $size22;
        line-height: $size24;
        font-weight: 800;
        margin: 0 !important;

        @include media-breakpoint-up(md) {
          font-size: $size24;
        }

        @include media-breakpoint-up(xl) {
          font-size: $size32;
        }

        @media only screen and (max-width: map-get($grid-breakpoints, xl)) and (orientation: landscape) {
          font-size: $size26;
        }
      }

      .top {
        top: -$size48;
        left: 12%;
      }
      .bottom {
        bottom: -$size80;
        right: 20%;
      }

      .btn-login {
        width: $size112;
        @include media-breakpoint-up(sm) {
          width: $size128;
        }
        @include media-breakpoint-up(xl) {
          width: auto;
        }
      }
    }

    .getstarted-user-icon {
      height: 24px;
      margin-left: $size8;
    }

    .getstarted-bottom-blub {
      position: absolute;
      z-index: -1;
      width: 13rem;
      bottom: -8rem;
      right: -9%;

      @include media-breakpoint-up(xl) {
        width: 23rem;
        bottom: -12rem;
        right: -15%;
      }
    }

    &.container {
      max-width: 100%;
      padding: 0 $size32;

      @include media-breakpoint-up(md) {
        padding: 0 $size64;
      }

      @include media-breakpoint-up(lap) {
        max-width: 1308px;
      }
    }

    .btn {
      z-index: 1;
    }
  }

  .back {
    background-color: $gray-350;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 120px;
    &-bg {
      background: url('~assets/svg/public/blubs/pattern_green.svg') repeat center;
      opacity: 0.5;
      height: 100%;
      width: 100%;
    }
  }

  .logo-section {
    color: $gray-350;
    margin-top: $size64;

    @include media-breakpoint-up(md) {
      margin-top: $size120;
    }

    @media only screen and (max-width: map-get($grid-breakpoints, xl)) and (orientation: landscape) {
      margin-top: 148px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 180px;
    }

    &--with-button {
      margin-top: $size64;

      @include media-breakpoint-up(xl) {
        margin-top: 172px;
      }
    }
  }

  .mobileapp-section {
    z-index: 11;
    .col {
      min-height: 300px;
    }

    &-text {
      & > div {
        max-width: 226px;
        @include media-breakpoint-up(md) {
          max-width: 100%;
        }
      }
    }

    .mobileapp-subtext {
      margin-bottom: 0 !important;
      font-weight: $font-weight-normal;
      font-size: $size16;

      @include media-breakpoint-up(md) {
        font-size: $size18;
      }
      @include media-breakpoint-up(xl) {
        font-size: $size28;
      }
    }

    .mobileapp-phone {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $size40;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }

      img {
        width: 282px;
        height: 490px;
        margin-bottom: -$size28;

        @include media-breakpoint-up(md) {
          width: 240px;
          height: 420px;
          margin-right: -$size32;
          margin-bottom: -$size32;
        }

        @include media-breakpoint-up(xl) {
          width: 454px;
          height: 794px;
          // SVG shadow adds 56px "margin" in full resolution
          margin-right: -$size56;
          margin-bottom: -$size56;
        }
      }
    }

    .mobileapp-stores {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }

      a:nth-of-type(1) {
        margin-right: $size8;

        @include media-breakpoint-up(xl) {
          margin-right: $size16;
        }
      }
      a:nth-of-type(2) {
        margin-right: $size4;

        @include media-breakpoint-up(xl) {
          margin-right: $size6;
        }
      }

      img {
        width: 100px;
        height: 30px;

        @include media-breakpoint-up(md) {
          width: $size80;
          height: $size24;
        }

        @include media-breakpoint-up(xl) {
          width: 156px;
          height: 46px;
        }
      }
    }

    .mobileapp-wrapper {
      height: 26rem;
      @include media-breakpoint-up(xl) {
        margin-top: -$size64;
      }
    }

    h4 {
      margin-bottom: 0 !important;
      font-weight: bold;
      font-size: $size16;

      @include media-breakpoint-up(md) {
        font-size: $size18;
      }
      @include media-breakpoint-up(xl) {
        font-size: $size28;
      }
    }
    .blub {
      position: absolute;
      &.mobileapp {
        left: -$size16;
        width: $size40;
        top: $size80;
        z-index: -1;

        @include media-breakpoint-up(xl) {
          width: $size64;
          left: -$size20;
          top: $size160;
        }
      }
    }

    &.container {
      @extend .container-padding;
    }

    .mmaritime,
    .tatoi,
    .iyc,
    .monospace {
      position: relative;
      width: 8.5rem;
      height: 8.5rem;
      .logo {
        position: absolute;
        height: 100%;
        width: 100%;
        svg {
          cursor: pointer;
          position: relative;
          z-index: 1;
          &:hover {
            color: $dark-blue;
          }
        }
      }

      .blubs {
        position: absolute;
        height: 100%;
      }

      @include media-breakpoint-up(xl) {
        width: 16rem;
        height: 16rem;
      }
    }

    .mmaritime {
      top: 8%;
      left: -$size16;
      .logo {
        left: $size44;
        bottom: 4px;
      }
      svg {
        width: $size56;
        color: $white;
      }

      @include media-breakpoint-up(xl) {
        svg {
          width: $size160;
          height: 3.5rem;
        }
        .logo {
          left: $size56;
        }
      }
    }

    .tatoi {
      top: -$size24;
      right: -$size24;

      .logo {
        left: $size44;
        &.mini {
          height: 3rem;
          top: 50%;
          left: 3rem;
        }
      }
      svg {
        width: $size80;
        color: $white;
        height: 1.3rem;
      }
      @include media-breakpoint-up(sm) {
        top: -1.8rem;
        right: 0;
      }
      @include media-breakpoint-up(xl) {
        top: 0;
        svg {
          width: $size160;
        }
        .logo {
          left: $size72;
          &.mini {
            height: 5rem;
            top: 54%;
            left: 5.5rem;
          }
        }
      }
    }

    .monospace {
      bottom: -$size80;
      left: -$size16;

      .img-container {
        position: relative;
        top: 36%;
        height: $size40;

        .logo {
          cursor: pointer;
          left: 2.4rem;
          width: $size64;
          height: $size40;

          &.mini {
            left: $size56;
            top: -$size40;
          }
        }
        .colored,
        &:hover .white {
          display: none;
        }

        &:hover .colored {
          display: block;
        }
      }

      &.mono-wrapper {
        bottom: -6rem;
        left: 1rem;
        .logo {
          width: $size56;
        }
        @include media-breakpoint-up(sm) {
          left: 0;
          bottom: -7rem;
        }
      }

      @include media-breakpoint-up(xl) {
        .img-container {
          top: 42%;
          .logo {
            width: $size96;
            left: 4.75rem;
            &.mini {
              left: $size104;
              top: -$size96;
              height: $size80;
            }
          }
        }
      }
    }

    .iyc {
      top: $size96;

      .img-container {
        height: $size54;
        position: relative;
        top: calc(50% - 2rem);
        .logo {
          cursor: pointer;
          left: $size48;
          top: 0;
          width: $size48;
          @include media-breakpoint-up(xl) {
            width: $size96;
            left: $size88;
          }
        }
        .colored,
        &:hover .white {
          display: none;
        }

        &:hover .colored {
          display: block;
        }
      }

      @include media-breakpoint-up(sm) {
        top: calc(50% - 8rem);
      }
      @include media-breakpoint-up(xl) {
        .logo {
          left: $size96;
          top: -0.5rem;
          width: $size80;
        }
      }
    }
  }

  .gray_fluid {
    position: absolute;
    bottom: 270px;
    left: 43%;
    width: 92px;
    height: 92px;
  }

  .footer {
    position: relative;
    line-height: $size32;
    font-size: $size16;
    color: #1a1423;
    font-weight: 200;
    height: 130px;

    @include media-breakpoint-up(md) {
      height: 120px;
    }

    @include media-breakpoint-up(xl) {
      height: 180px;
    }

    &-logo {
      margin-top: $size12;
      margin-bottom: $size12;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    span {
      font-size: $size12;
      font-weight: $font-weight-normal;
      line-height: 15px;

      @include media-breakpoint-up(xl) {
        font-size: $size16;
        line-height: 20px;
      }
    }

    a {
      color: $brainy-green;
      font-weight: $font-weight-bold;
    }

    strong {
      font-weight: $font-weight-bold;
    }
  }

  .footer-wrapper {
    position: relative;
    z-index: 12;

    .back-top {
      right: 0;
      bottom: $size112;
      transform: scale(0.6) translateZ(0);
      transition: all 0.35s cubic-bezier(0.6, 0, 0.26, 0.99);
      opacity: 0;
      visibility: hidden;
      z-index: 1;
      @include media-breakpoint-up(md) {
        bottom: $size16;
      }
      @include media-breakpoint-up(xl) {
        bottom: 11rem;
      }
      &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1) translateZ(0);
      }
    }
  }

  .intro-bg {
    position: absolute;
    top: -800px;
    right: 0;
    width: 100%;
    height: 1680px;
    background-image: url('~assets/svg/public/backgrounds/intro_screen_small.svg');
    background-size: cover;
    background-position: center;
    z-index: 2;

    @include media-breakpoint-up(sm) {
      background-image: url('~assets/svg/public/backgrounds/intro_screen_large.svg');
      width: 1576px;
      height: 1024px;
      top: 0;
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      height: 1360px;
      top: 0;
    }

    @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
      background-image: url('~assets/svg/public/backgrounds/intro_screen_large.svg');
      height: 1200px;
      top: 0;
      top: 0;
    }

    > div {
      height: inherit;
      width: inherit;
      margin: 0 !important;

      svg {
        height: inherit;
        width: 1864px !important;
      }
    }
  }

  .intro-footer-bg {
    position: absolute;
    bottom: -978px;
    left: 0;
    background-image: url('~assets/svg/public/backgrounds/intro_footer_mobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    width: 100%;
    height: 2043px;
    background-position: center bottom;
    opacity: 0.4;

    @include media-breakpoint-up(md) {
      bottom: -160px;
      background-image: url('~assets/svg/public/backgrounds/intro_footer_tablet.svg');
      height: 1400px;
    }

    @include media-breakpoint-up(xl) {
      bottom: -246px;
      background-image: url('~assets/svg/public/backgrounds/intro_footer_desktop.svg');
      height: 2122px;
    }

    @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
      background-image: url('~assets/svg/public/backgrounds/intro_footer_desktop.svg');
    }
  }
}

.splash-screen {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  opacity: 1;
  height: 100%;

  overflow: hidden;
  transition: transform 0.35s cubic-bezier(0.6, 0, 0.26, 0.99) 1.5s, opacity 0.35s ease-in-out 1.5s,
    height 750ms ease-in-out 2s;

  transform: scale(1) translateZ(0);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &::before {
    background-color: #fff;
  }

  &::after {
    background-color: $gray-350;
    left: 50%;
  }

  .splash-screen--content {
    .splash-screen--content-animated {
      width: 204px;
      height: 280px;
    }

    .splash-screen--content-text {
      width: 192px;
      height: 28px;
      background: url('~assets/svg/public/logo_text.svg') no-repeat center;
    }

    @media only screen and (max-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
      .splash-screen--content-animated {
        height: 200px;
      }

      .splash-screen--content-text {
        width: 152px;
      }
    }
  }

  &.loaded {
    height: 0;
    opacity: 0;
    transform: scale(0.6) translateZ(0);
  }
}

.mobile-menu-bottom {
  bottom: 116px;

  a {
    color: $brainy-green;
    font-size: $size14;
    font-weight: $font-weight-bold;
    line-height: 18px;
  }
}

.plugin-screens {
  position: relative;
  padding-right: 0;
  margin: 0 $size16;

  @include media-breakpoint-up(md) {
    margin: $size4 $size32 $size32;
  }
  @include media-breakpoint-up(xl) {
    margin-left: 0;
  }

  img {
    filter: drop-shadow(10px 40px 60px rgba(69, 63, 81, 0.2));
    border-radius: $size6;
  }

  &-back {
    width: 100% !important;
    margin-left: 0;
    margin-bottom: 0;
    top: 0;

    @include media-breakpoint-up(md) {
      width: 75% !important;
      margin-left: 25%;
      margin-bottom: $size32;

      &--with-side {
        margin-left: 17%;
      }

      &--without-front {
        width: 65% !important;
        margin-left: 11.8%;
        margin-bottom: 0;
      }
    }
  }

  &-front {
    width: 55% !important;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;

    &--with-side {
      width: 40% !important;
    }

    @include media-breakpoint-up(md) {
      opacity: 1;
    }
  }

  &-side {
    width: 23% !important;
    position: absolute;
    right: 0;
    top: 35%;
    z-index: 2;
    opacity: 0;

    &--without-front {
      width: 30% !important;
      top: 17%;
    }

    @include media-breakpoint-up(md) {
      opacity: 1;
    }
  }
}

.pricing-contact-input > input {
  border-bottom: 1px solid #ecedf1;
  font-weight: $font-weight-bold;
  height: $size40;
  color: $white;

  &::placeholder {
    color: #ecedf1;
  }

  &:focus {
    color: $white;
    border-bottom: 1px solid $white;
  }
}

.btn-public-white {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 8px 0 rgba(26, 20, 35, 0.1);
  color: $brainy-green;
  font-weight: $font-weight-bold;
  border-radius: 3px;
  cursor: pointer !important;
  background: $white;
  height: $size32;
  width: 140px;
  font-size: $size14;
  transition: 0.2s ease-in-out;

  &.contact-section-btn {
    width: 136px !important;
  }

  &:hover {
    box-shadow: none !important;
    transition: 0.2s ease-in-out;
    background-color: #f7fdfd;

    &.text-primary {
      background-color: #f8f8f9; // Same as $black text with 3% opacity
    }

    svg {
      transform: translate(4px);
      transition: 0.2s ease-in-out;
    }
  }

  &-auto {
    padding: 0 $size16;
    width: fit-content !important;

    @include media-breakpoint-up(xl) {
      padding: 0 $size24;
    }
  }

  &-header {
    margin-top: $size16;
    padding: 0 $size12;
    width: auto;
    box-shadow: 2px 2px 10px 0 rgba(0, 211, 205, 0.2);

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }

  &-inner-page {
    width: 126px;
    height: $size32;
    box-shadow: 2px 2px 10px 0 rgba(0, 211, 205, 0.2);

    @include media-breakpoint-up(xl) {
      width: 240px;
      height: $size56;
    }
  }

  svg {
    transition: 0.2s ease-in-out;
    color: $brainy-green;
    margin-left: $size24;
  }

  @include media-breakpoint-up(xl) {
    height: $size48;
    width: 180px;
    font-size: $size18;

    img {
      width: $size12;
      margin-left: $size32;
    }
  }
}

.getstarted-section .btn-public-white {
  @include media-breakpoint-up(md) {
    width: 140px;
  }
  @media only screen and (max-width: map-get($grid-breakpoints, xl)) and (orientation: landscape) {
    width: 110px;
  }
  @include media-breakpoint-up(xl) {
    width: 180px;
  }
}
