.accoma-indicator {
  position: absolute;
  top: $size24;
  right: $size32;
  background-color: $accoma;
  line-height: $size16;
  border-radius: 3px;
}

.whos-in {
  .table-extra {
    padding-bottom: $size9 !important;
  }

  .table-mm--search {
    .input-wrapper {
      margin-bottom: $size2 !important;
    }
  }

  .pagination-container {
    margin-bottom: $size12 !important;
  }

  &__container {
    &-people-types {
      height: $size64;
      max-width: 25rem;
      width: 100%;
      overflow-x: auto;

      @include media-breakpoint-up(xl) {
        max-width: 38rem;
      }

      @include media-breakpoint-up(lap) {
        max-width: 44rem;
      }

      @include media-breakpoint-up(xxl) {
        max-width: 48rem;
      }

      @include media-breakpoint-up(hd) {
        max-width: 54.875rem;
      }

      @extend.scrollbar;
    }

    &-separator {
      height: $size44;
      min-width: 1px;
      background-color: $border-color;
      margin: 0 $size8 0 $size22;
    }

    .type-card {
      min-width: 8rem;
      height: $size40;
      border-radius: 0 7px 7px 0;
      cursor: pointer;
      transition: all 0.2s ease-in;

      &__body {
        padding: $size2 0 $size2 $size14;
      }

      &__separator {
        height: $size12;
        min-width: 1px;
        margin-right: $size12;
        margin-left: $size12;
      }

      &__stripe {
        min-width: $size4;
        transition: all 0.2s ease-in;
      }

      &__title {
        width: $size96;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.inactive {
        background-color: rgba(white, 0.8);

        &:not(:hover) {
          .type-card__stripe {
            background-color: $border-color !important;
          }
        }

        .type-card__title,
        .type-card__amount,
        .type-card__percentage {
          color: $info-text;
        }

        .type-card__separator {
          background-color: $border-color;
        }
      }

      &.active,
      &:hover {
        background-color: white;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

        .type-card__separator {
          background-color: $border-color;
        }

        .type-card__title,
        .type-card__amount,
        .type-card__percentage {
          color: $primary;
        }
      }
    }
  }
}
