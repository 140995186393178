.intro-page {
  .brainy-logo {
    position: fixed;
    top: $size24;
    left: $size24;

    svg {
      width: 80px;
      height: 104px;
    }

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: $size16;
      left: -36px;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: $size32;
      left: -75px;

      svg {
        width: 164px;
        height: auto;
      }
    }
  }

  .right-section {
    max-width: 8em;

    @include media-breakpoint-up(md) {
      max-width: 15em;
    }

    @include media-breakpoint-up(lg) {
      max-width: 20rem;
    }

    @include media-breakpoint-up(lap) {
      max-width: 30rem;
    }

    @include media-breakpoint-up(hd) {
      max-width: 42.5rem;
    }
  }

  .organizations-wrapper {
    height: 178px; // Two organizations visible
    overflow-y: auto;

    &:not(.invitations) {
      @include media-breakpoint-up(hd) {
        height: 268px; // Three organizations visible
      }
    }

    .organization-preview {
      width: $size56;
      height: $size56;
      border-radius: $border-radius-base;

      .logo-image {
        width: $size40;
        height: $size40;
      }

      .organization-letter {
        font-size: $size32;
      }
    }
  }

  .organization-create {
    .create-box {
      width: $size56;
      height: $size56;
      border: 1px dashed $public-text;
      transition: all 0.3s ease 0s;
      border-radius: 5px;
    }

    &:hover .create-box {
      border-style: solid;
    }
  }
}
