.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.range-datepicker-wrapper .DateRangePicker_picker {
  z-index: 5;
}

.DateInput {
  width: 7rem !important;

  .DateInput_input {
    height: $size32;
    padding: $size4 $size16;
    font-size: $font-size-base;

    &:focus,
    &.DateInput_input__focused {
      border-color: $light-blue;
    }
  }
}

.DayPicker {
  > div {
    display: flex;
  }

  .DayPickerNavigation {
    .DayPickerNavigation_button {
      outline: none !important;
      border: none;
      width: $size24;
      height: $size32;
      background: url('~assets/svg/general/calendar_arrow.svg') no-repeat center;
      background-origin: content-box;
      padding: 6px;

      svg {
        display: none;
      }

      &:first-child {
        transform: rotate(90deg);
      }

      &:last-child {
        transform: rotate(-90deg);
      }
    }
  }

  .CalendarMonth_caption {
    font-size: $size14;
    font-weight: 300;
    padding-bottom: $size40;

    strong {
      font-weight: 600;
    }
  }

  .DayPicker_weekHeader {
    top: 54px;
    color: $body-color;

    small {
      font-weight: 600;
    }
  }
}

.SingleDatePicker {
  @extend .d-flex;
  @extend .align-items-center;

  &:before {
    content: '';
    background: url('~assets/svg/general/calendar.svg') no-repeat center;
    background-size: contain;
    display: inline-block;
    min-width: $size18;
    max-width: $size18;
    height: $size18;
    margin-right: $size16;
  }

  .SingleDatePickerInput {
    height: $size40;
    border-color: $border-color;

    .DateInput_fang {
      display: none;
    }
  }

  .SingleDatePicker_picker {
    z-index: 2;
  }

  .DateInput {
    height: 100%;
    width: $size112 !important;

    .DateInput_input {
      height: 100%;
      border: none !important;
      text-align: center;
      outline: none;
    }
  }

  .CalendarDay {
    border: none !important;
    outline: none !important;
    font-size: $size14;
    box-shadow: inset 0px 2px 0px 0px #ffffff, inset 0px -2px 0px 0px #ffffff;

    &.CalendarDay__selected,
    &.CalendarDay__selected_span,
    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover,
    &:hover {
      background: $gray-100;
      color: $body-color;
    }

    &.CalendarDay__today {
      color: $light-blue;
      font-weight: 600;
    }
  }
}

.DateInput_fangStroke {
  stroke: #dfe3e6 !important;
}
.DayPicker__withBorder {
  box-shadow: 4px 6px 32px -3px #9e9e9e, 0 0 0 1px #dfe3e6;
}

.with-month-element {
  .month-select {
    background-color: white;
    border-color: $border-color;
  }
}

.SingleDatePicker {
  + .form-error {
    left: $size34 !important;
  }
}

.month-select {
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 5px;
  appearance: none;
  font-weight: 400;
  padding: 2px $size14;
}

.month-dropdown {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  max-height: 15rem;
  width: fit-content;
  background: $white;
  box-shadow: none !important;
  border: 1px solid $border-color;
  @extend.scrollbar;

  .month-select-option {
    padding: $size8 $size16;
    background: $white;
    color: $primary;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: $light-blue;
    }
  }

  .popover-body {
    padding: 0 !important;
  }
}

body > div:not(#root) {
  .SingleDatePicker_picker {
    z-index: 1100;
  }
  .CalendarDay {
    border: none !important;
    outline: none !important;
    font-size: $size14;
    box-shadow: inset 0px 2px 0px 0px #ffffff, inset 0px -2px 0px 0px #ffffff;

    &.CalendarDay__selected,
    &.CalendarDay__selected_span,
    &.CalendarDay__hovered_span,
    &.CalendarDay__hovered_span:hover,
    &:hover {
      background: $gray-100 !important;
      color: $body-color !important;
    }
    &.CalendarDay__selected_start {
      border-radius: 10px 0px 0px 10px;
      color: white !important;
    }
    &.CalendarDay__selected_end {
      border-radius: 0 10px 10px 0px;
      color: white !important;
    }
    &.CalendarDay__selected_end,
    &.CalendarDay__selected_start {
      z-index: 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: $size24;
        height: $size24;
        background: $light-blue;
        left: 14%;
        top: 13%;
        z-index: -1;
        border-radius: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.popover {
  &.month_picker {
    max-width: 308px;
    .btn {
      color: $gray-900;
      &.month {
        &:hover,
        &.active {
          border: 1px solid $light-blue;
        }
      }
    }
  }
  .popover-inner,
  .cursor-auto {
    cursor: auto;
  }
  .btn {
    .svg-wrap {
      z-index: -1;
    }
  }
  .switch {
    z-index: -1;
  }

  .form-control {
    font-size: $size10 !important;
    padding: $size8;
  }

  .SingleDatePicker {
    &::before {
      display: none;
    }

    .DateInput {
      width: $size72 !important;

      .DateInput_input {
        font-size: $size10 !important;
        padding: $size8 $size4;
      }
    }
  }
}
