.horizontal-slider {
  width: 100%;
  max-width: 100%;
  min-width: $size128;
  height: $size24;
}
.vertical-slider {
  height: 380px;
  width: $size32;
}
.handle {
  font-size: 0.9em;
  text-align: center;
  background-color: $primary;
  color: white;
  cursor: pointer;
}
.handle.active {
  background-color: $gray-650;
}
.bar {
  position: relative;
  background: $primary;
}
.bar.bar-1 {
  background: $gray-500;
}

.horizontal-slider .bar {
  top: $size12;
  height: $size4;
}
.horizontal-slider .handle {
  top: 0;
  width: $size40;
  height: $size26;
  line-height: $size26;
  border-radius: 3px;
  box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.3);
  font-weight: 600;

  .handler-text {
    top: -$size16;
    line-height: $size12;
    color: #3d70b2;
  }
}
.vertical-slider .handle {
  left: 1px;
  width: $size32;
  line-height: 50px;
}
.vertical-slider .bar {
  left: 20px;
  width: 10px;
}
