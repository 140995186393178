.add-widget {
  position: absolute;
  top: $size40;
  right: $size56;
  padding: 0;
}

.no-gadgets {
  margin: 160px auto 0;
  width: 520px;
  border-radius: 2px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  overflow: hidden;

  &:hover {
    .top .gear {
      transform: rotate(-45deg);
    }
  }

  .top {
    padding: $size40;
    background-color: #d2dfef;
    font-weight: 600;
    font-size: $size18;
    position: relative;

    .gear {
      position: absolute;
      right: -$size20;
      top: -$size24;
      transition: 1s all;
      transform: rotate(0deg);
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $size64 $size72 $size64 $size40;
  }
}

.dashboard {
  overflow: auto;
  padding-bottom: 4px;
}
