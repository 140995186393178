@import 'node_modules/@fullcalendar/core/main';
@import 'node_modules/@fullcalendar/daygrid/main';
@import 'node_modules/@fullcalendar/resource-timeline/main';
@import 'node_modules/@fullcalendar/timegrid/main';
@import 'node_modules/@fullcalendar/timeline/main';

.default-calendar {
  position: relative;

  .loading {
    bottom: unset;
    top: 36px;
    height: 28px;

    .loading-text {
      font-size: $size12;
      margin-top: $size4;
    }

    .loading-bar {
      background: $primary;
      height: 6px;
    }
  }

  .bookings-calendar--categories {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    .btn {
      flex: 1;
      min-width: $size64;

      @include media-breakpoint-up(hd) {
        min-width: $size168;
      }

      &.active,
      &:hover {
        background: currentColor !important;
        border-color: currentColor !important;

        > span {
          color: #fff;
        }
      }

      &.inactive:not(:hover) {
        border-color: $calendar-border-color;

        > span {
          color: $info-text;
        }
      }
    }
  }

  .bookings-calendar--datepicker {
    position: absolute;
    top: 0;
    right: 14rem;

    @include media-breakpoint-up(xl) {
      right: 20rem;
    }

    .SingleDatePicker_picker__directionLeft {
      margin-left: -15rem;
    }

    .SingleDatePicker {
      &::before {
        display: none;
      }

      .SingleDatePickerInput {
        height: $size32;
        background: none;
        border: 0;
        display: flex;
        align-items: center;
      }

      .DateInput {
        background: url('~assets/svg/general/calendar.svg') no-repeat center;
        width: $size18 !important;
        height: $size18;
        background-size: contain;

        .DateInput_input {
          font-size: 0;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }
  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover,
  .fc-unthemed .fc-list-view,
  .fc-unthemed .fc-list-heading td {
    border-color: $calendar-border-color;
  }

  &.bookings-calendar {
    .fc-header-toolbar {
      .fc-center {
        .fc-button {
          color: $light-blue;

          &:active {
            border-color: transparent;
            color: $light-blue;
          }
        }

        h2 {
          min-width: 15rem;
          text-align: center;

          @include media-breakpoint-up(xl) {
            min-width: 19rem;
          }
        }
      }

      .fc-button-active {
        color: $light-blue !important;
        border-color: $light-blue !important;
        z-index: 1;
      }
    }

    .fc-fri {
      border-right: 1px solid $gray-550 !important;
    }

    .fc-agendaWeek-view {
      .fc-time-grid-event .fc-content .fc-time {
        line-height: 1;
      }
    }
  }

  .fc-header-toolbar {
    margin-bottom: $size32;
    @extend .d-flex;
    @extend .align-items-center;

    > div {
      flex: 1;

      &.fc-left {
        order: 1;
      }

      &.fc-center {
        order: 2;
      }

      &.fc-right {
        order: 3;
        display: inline-flex;
        justify-content: flex-end;
      }

      &.fc-clear {
        display: none;
      }
    }

    .fc-button {
      @extend .btn;
      outline: none !important;
      text-shadow: none;
      font-weight: 300;
    }

    .fc-center {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 $size40;

      // @include media-breakpoint-up(xl) {
      //   padding: 0 $size24;
      // }

      h2 {
        font-size: $size14;
        line-height: 17px;
        font-weight: 400;

        span {
          &:last-child {
            display: block;
          }
        }

        @include media-breakpoint-up(xl) {
          span {
            &:last-child {
              display: inline;
            }
          }
        }

        @include media-breakpoint-up(hd) {
          font-size: $size16;
        }
      }

      .fc-button {
        padding: 0;
        background: none !important;

        .fc-icon {
          &.fc-icon-left-single-arrow,
          &.fc-icon-right-single-arrow {
            background: url('~assets/svg/general/calendar_arrow.svg') no-repeat center;
            width: $size16;
            height: $size8;

            &:after {
              color: transparent;
            }
          }

          &.fc-icon-left-single-arrow {
            transform: rotate(90deg);
          }

          &.fc-icon-right-single-arrow {
            transform: rotate(-90deg);
          }
        }
      }
    }

    .fc-right {
      .fc-navigate-button {
        background: url('~assets/svg/general/calendar.svg') no-repeat center !important;
        padding: 0;
        width: $size24;
        height: $size32;
      }

      .fc-button {
        @extend .btn-sm;
        @extend .btn-outline-secondary;
        text-transform: uppercase;
        height: $size32;
        padding-left: $size16;
        padding-right: $size16;
        margin: 0;
        color: $info-text;
        border-color: $calendar-border-color;
        border-radius: 0;
        background: none;

        &:hover:not(.fc-state-disabled):not(:disabled) {
          background-color: $light-blue;
          color: #fff !important;
        }

        &:disabled:not(.fc-today-button) {
          border-color: $gray-400;
          color: $gray-400 !important;
          cursor: not-allowed;
          background: transparent !important;
          opacity: 1;
        }

        &.fc-today-button,
        &.fc-state-active {
          color: $light-blue;
          border-color: $light-blue;
        }

        &.fc-today-button {
          margin-right: $size8;

          @include media-breakpoint-up(xl) {
            margin-right: $size16;
          }
        }

        + .fc-button {
          margin-left: -1px;
        }

        &:not(.fc-today-button) {
          font-size: 0;

          &::first-letter {
            font-size: $size14;
          }

          @include media-breakpoint-up(xl) {
            font-size: $size14;
          }
        }
      }
    }
  }

  .fc-view-container {
    background: #fff;
    overflow-y: auto;
  }

  .fc-widget-header {
    .fc-today {
      color: $red-400;
    }
  }

  .fc-timeline,
  .fc-agenda-view {
    .fc-head {
      .fc-cell-content {
        padding: $size24;
      }
    }

    .fc-body {
      .fc-resource-area {
        .fc-cell-content {
          padding: $size16 $size24;
          min-height: $size48;
          display: flex;
          align-items: center;
          height: calc(100% - #{$size32});
          &.special {
            height: calc(100% - #{$size16});
            .tooltip {
              display: none;
              top: $size8;
              left: $size4;
            }
          }
          .fc-cell-text {
            color: $info-text;
            display: inline-block;
            line-height: $size14;
            font-size: $size10;

            &::first-line {
              color: $body-color;
              font-size: $size14;
            }
          }
        }

        .fc-divider {
          .fc-cell-content {
            min-height: $size16;
            padding: $size4 $size16;
          }
        }
      }
    }

    .fc-widget-header {
      &.fc-resource-area {
        .fc-widget-header {
          color: $dark-red;
        }
      }

      &.fc-time-area {
        .fc-widget-header {
          text-align: center;
        }
      }
    }

    .fc-event-container {
      padding-bottom: 0;
      top: 0;
      z-index: initial !important;
      .fc-timeline-event,
      .fc-time-grid-event {
        height: $size80;
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
        border-left-width: 4px;
        display: flex;

        .fc-content {
          padding: $size8 $size4;
          color: $primary;
          width: 100%;
          overflow: hidden;

          .fc-time {
            display: block;
            font-size: 0.7rem;
            font-weight: 400;
            margin-bottom: $size4;
          }

          .fc-title {
            font-size: 0.7rem;
            font-weight: 400;
            text-transform: uppercase;
            display: block;
            line-height: $size24;

            &::first-line {
              font-weight: 600;
              line-height: $size20;
            }
          }
        }

        &.has-warnings,
        &.is-confidential {
          &:before {
            content: '';
            display: block;
            width: $size16;
            height: $size16;
            position: absolute;
            top: $size4;
            right: $size8;
            // z-index: 1;
          }
        }

        &.has-warnings {
          &:before {
            background: url('~assets/svg/general/warning-booking.svg') no-repeat center;
          }
        }

        &.is-confidential {
          &:before {
            width: 22px;
            background: url('~assets/svg/general/confidential.svg') no-repeat center;
          }
        }
      }
    }
  }

  .fc-agenda-view {
    .fc-resizer.fc-end-resizer {
      display: none;
    }

    .fc-widget-header {
      > span,
      .tooltip-holder {
        padding: $size24;
        display: inline-block;
        white-space: nowrap;
        min-width: 120px;
      }
    }
    .fc-axis {
      padding: 0 $size24 !important;
    }

    .fc-bg {
      .fc-today:not(.special-day) {
        background-color: rgba($red-400, 0.06) !important;
      }
    }

    .fc-event-container {
      .fc-time-grid-event {
        // display: block;
        height: auto;
      }
    }

    &.fc-agendaWeek-view {
      &.fc-agenda-view {
        min-width: 1200px;
      }

      .fc-body .fc-event {
        border-color: currentColor;
        border-radius: 5px;

        .fc-bg {
          background: #e6eef7;
          opacity: 1;
        }

        &.is-visit .fc-bg {
          background: #e9e9e9;
        }

        &.is-out-of-campus {
          border-color: #a12941 !important;
          .fc-bg {
            background: #f1dfe3;
          }
        }
        &.is-confidential .fc-bg {
          background: #ffeae8;
        }

        &.is-me-as-participant:not(.is-confidential) .fc-bg {
          background: #dceeed;
        }
      }
    }
  }

  .fc-agendaWeek-view,
  .fc-agendaDay-view {
    .is-external {
      display: none !important;
    }
  }

  .is-external {
    pointer-events: none;
    border-width: 0px !important;

    .fc-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .fc-bg {
      background-color: $info-text !important;
    }
  }

  &.is-loading {
    .fc-button {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.8;
    }
  }

  .fc-body .fc-event,
  .fc-body .fc-event:hover {
    color: $light-blue;
    border-color: currentColor;
    opacity: 1 !important;

    &.is-visit {
      color: $gray-800;
    }

    &.is-out-of-campus {
      color: $red-700;
      border-color: #a12941 !important;
    }

    &.is-confidential {
      color: $red-400;
    }

    // &.is-me-as-participant:not(.is-confidential) {
    //   color: $green;
    // }

    .fc-bg {
      background: currentColor;
      opacity: 0.15;
    }
  }
}

.fc-nonbusiness.fc-bgevent,
.closed-day {
  background: rgba(223, 223, 223, 0.2);
}

.closed-leave-day {
  background: rgba(223, 223, 223, 0.2);
}

.default-calendar {
  .fc-resourceTimelineTwoDay-view,
  .fc-agendaDay-view {
    .special-day,
    .is-today,
    .fc-bg .fc-today:not(.special-day) {
      background: $white !important;
    }
  }
}

.booking-calendar-popover {
  .popover-body {
    padding: 0;

    .bookings-calendar--info {
      > div {
        &::before {
          content: '';
          width: $size8;
          height: $size8;
          display: inline-block;
          border-radius: 100%;
          margin-right: $size8;
          vertical-align: middle;
          margin-top: -2px;
        }

        &.bookings-calendar--info-confidential:before {
          background: $red-400;
        }

        &.bookings-calendar--info-me-as-participant:before {
          background: $green;
        }

        &.bookings-calendar--info-in-campus:before {
          background: $light-blue;
        }

        &.bookings-calendar--info-visit:before {
          background: $gray-800;
        }

        &.bookings-calendar--info-out-of-campus:before {
          background: $red-700;
        }

        &.bookings-calendar--info-external:before {
          background: $info-text;
        }
      }
    }
  }
}

.calendar-load-more {
  position: absolute;
  bottom: 1px;
  left: 1px;
  padding-left: $size24;
  background: #fff;
  z-index: 2;
  width: 240px;
  height: $size20;

  .btn {
    line-height: 16px;
  }
}

.special-day-tooltip {
  position: relative;

  .tooltip-handler {
    height: 16px;
    width: 16px;
    position: absolute;
    cursor: pointer;
    z-index: 100;
    background: url('~assets/svg/plugins/human-resources/special_day.svg') no-repeat center;

    &:hover {
      + .tooltip.special-tooltip {
        opacity: 1;
        display: block;
      }
    }
  }

  > .tooltip-holder {
    position: relative;

    .tooltip-handler {
      top: $size24;
      left: 0;
    }

    .tooltip.special-tooltip {
      top: calc(50% - 20px);
    }
  }
}

.tooltip.special-tooltip {
  z-index: 101;
  width: $size136;
  display: none;
  top: calc(50% - 40px);
  left: $size24;
  opacity: 0;
  font-size: $size12;

  .tooltip-inner {
    box-shadow: 4px 6px 32px -3px #9e9e9e;
    padding: $size8 $size16;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    top: calc(50% - 0.4rem);
    left: 0%;
  }
}

.special-day {
  background: rgba(96, 143, 201, 0.12) !important;
}

.leaves-calendar,
.shifts-calendar {
  .special-day-tooltip {
    .tooltip-handler {
      top: -8px;
      left: calc(50% - 8px);
    }
  }
}

.shifts-calendar {
  .fc-monthly-view {
    .special-day-tooltip {
      &:nth-last-child(3),
      &:nth-last-child(2),
      &:last-child {
        .tooltip.special-tooltip {
          left: calc(-100% - 88px);

          .arrow {
            left: initial;
            right: 1px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .fc-resourceTimelineTwoDay-view {
    .special-day-tooltip::after {
      display: none;
    }

    .tooltip-handler {
      display: none;
    }
  }

  .fc-resourceTimelineWeek-view {
    .special-day-tooltip.fc-today .tooltip-handler,
    .special-day-tooltip .tooltip-handler {
      top: 10px !important;
      left: 10px !important;
    }

    .tooltip.special-tooltip {
      top: calc(50% - 20px);

      .arrow {
        top: calc(50% - 0.6rem);
      }
    }
  }
}

.bookings-calendar {
  .fc-short {
    .fc-time {
      span {
        display: block;
      }

      &::before {
        content: attr(data-full) !important;
        display: none;
      }

      &::after {
        content: '' !important;
        display: none;
      }
    }
  }

  .fc-widget-header {
    &.fc-today:not(.special-day-tooltip) {
      > span {
        position: relative;

        // &::after {
        //   content: '';
        //   height: 16px;
        //   width: 16px;
        //   position: absolute;
        //   cursor: pointer;
        //   z-index: 1;
        //   top: $size24;
        //   left: 0;
        //   background: url('~assets/svg/plugins/human-resources/fc-today.svg') no-repeat center;
        // }
      }
    }
  }
}

.label-today,
.label-special {
  path {
    fill: $info-text;
  }
}

.fc-sat {
  border-left: 1px solid $gray-550 !important;
}

.fc-sun {
  border-right: 1px solid $gray-550 !important;
}
