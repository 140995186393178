.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
}

.modal-container {
  position: fixed;
  background: white;
  width: 380px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-title {
  font-size: $size18;
  border-bottom: 1px solid $border-color;
}

.modal-actions {
  button {
    border-radius: 1px;
  }

  .cancel {
    background-color: transparent;
    color: $dark-red;
    border-width: 0;
  }

  .confirm {
    background-color: $dark-red;
    color: white;
  }
}

.modal {
  .modal-dialog {
    height: calc(100% - 2rem);
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    @extend .align-items-center;
    @extend .justify-content-center;

    .modal-content {
      box-shadow: $modal-content-box-shadow-xs;

      .modal-header {
        padding: $size24;
        position: relative;

        h5 {
          font-size: $size24;
          border: none;
        }

        .close {
          padding: 0;
          position: absolute;
          outline: none !important;
          top: $size32;
          right: $size32;
          color: $secondary;
          cursor: pointer;
        }
      }

      .modal-body {
        padding: $size24;
        max-height: 60vh;
        overflow-y: auto;
        background: #fff;

        @include media-breakpoint-up(hd) {
          max-height: 80vh;
        }

        &.min-150 {
          min-height: 150px;
        }
      }

      .modal-footer {
        padding: $size24;
        background: #fff;
      }
    }

    &.modal-xl {
      max-width: 90%;

      @include media-breakpoint-up(lg) {
        max-width: 764px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 964px;
      }
    }
    &.modal-md {
      max-width: 90%;

      @include media-breakpoint-up(md) {
        max-width: 720px;
      }
      .form-error {
        left: 8rem !important;
      }

      &.accrue {
        .form-error {
          left: 0 !important;
        }
      }

      &.bulk-modal {
        .times .form-error {
          left: 0 !important;
        }
      }
    }

    .modal-info {
      font-weight: 300;
      font-size: $size12;
      span,
      div,
      ul {
        color: $watermelon;
      }
    }
  }
}

.modal-backdrop {
  &.qr-backdrop {
    opacity: 1;
    background: rgba(66, 66, 66, 0.3);
    &::after {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.orders-modal {
  max-width: 630px !important;

  .modal-title {
    width: 100%;

    display: flex;
    justify-content: space-between;

    .member-info {
      font-size: $size14;
      .name {
        color: $primary;
      }
    }
  }
  .modal-body {
    font-weight: 400;

    &.order-items {
      padding: 0 !important;

      max-height: 500px;
      overflow-y: auto;

      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      /* width */
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        width: 6px;
        border-radius: 3px;
        background: #aaaaaa;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .item:nth-child(even) {
        background-color: #f6f7f9;
      }
      .item:nth-child(odd) {
        background-color: white;
      }

      .item {
        .item-name {
          display: inline-block;
          font-size: $size20;
          padding-top: $size12;
          padding-left: $size24;
          padding-bottom: 4px;
          margin-bottom: $size8;
          border-bottom: 1px solid $green;
          font-weight: 300;
          color: $black;
        }

        .item-quantity {
          color: $blue;
        }

        .item-detail {
          display: flex;
          align-items: center;
          padding-bottom: $size12;
          padding-left: $size24;

          &.without {
            padding-bottom: $size8;
          }
          &.notes {
            align-items: flex-start;
            border: 1px solid $green;
            margin-left: $size12;
            padding-left: $size12;
            padding-top: 4px;
            padding-bottom: 4px;
            margin-right: $size12;
          }

          .item-detail-label {
            font-weight: 300;
            flex: 0 0 120px;
            font-size: $size16;
          }
          .item-detail-value {
            font-size: $size16;
            strike {
              color: $watermelon;

              white-space: pre;
            }
            span {
              color: $body-color;
            }
          }
        }

        .item-bottom-padding {
          height: 12px;
        }
      }

      .item-fancy {
        margin-left: $size24;
        color: white;
        background-color: $blue;
        position: relative;
        margin-top: $size12;
        padding: 4px 0;

        .svg-wrap {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .loading-area {
        height: 160px;
        position: relative;
      }

      .loader-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .loader-inner:after {
        background: #fff;
      }
    }
  }
}

.working-week-modal {
  .modal-title {
    width: 80%;
  }
}

.accrue {
  .modal-title {
    width: 80%;
  }
}

.terms-modal {
  width: 100%;
  max-width: 1148px;

  .modal-content {
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.21) !important;
  }

  .modal-main {
    padding: $size22 $size32 $size32 $size32;
    min-height: 400px;
  }

  .terms {
    height: 340px;
    border: 1px solid $gray-350;
    padding: $size26 $size24 $size8 $size24;
    position: relative;

    @include media-breakpoint-up(xl) {
      height: 520px;
    }

    .scrollbar {
      padding-bottom: $size40;
    }
  }

  .terms-form {
    padding: 0 $size32 $size32 $size48;
    font-size: $size16;
    font-weight: normal;

    .checkedBox,
    .uncheckedBox {
      margin-right: $size24 !important;
    }
  }

  .opacity-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $size40;
    background: linear-gradient(hsla(0, 0%, 100%, 0.1), #fff);
  }
}

.booking-modal {
  .notes {
    p:last-child,
    .upload-files,
    .upload-files--preview-item {
      margin-bottom: 0 !important;
    }

    a {
      color: $light-blue;
    }
  }
}

.pto-balance-history-name {
  font-weight: 600;
  font-size: $size14;
  color: $black;
}
.pto-balance-history-leftover {
  font-weight: 400;
  font-size: $size12;
}
.pto-balance-history-time {
  font-weight: 400;
  font-size: $size12;
  color: $info-text;
}
