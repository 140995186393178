@keyframes anim-phone {
  0% {
    transform: rotate(90deg);
    opacity: 0;
  }
  8% {
    transform: rotate(90deg);
    opacity: 1;
  }
  48% {
    transform: rotate(0deg);
    opacity: 1;
  }
  68% {
    transform: rotate(0deg);
    opacity: 1;
  }
  76% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 0;
  }
}
