// @import url('https://fonts.googleapis.com/css?family=Muli:200,400,700,900');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=greek');

@import './bootstrap/variables';
@import './bootstrap/imports';

@import './common/mixins';
@import './common/fonts';
@import './common/layouts';
@import './common/notifications';
@import './common/portait';
@import './common/responsive';
@import './common/transitions';
@import './common/sidebars';
@import './common/shared';
@import './common/modal';
@import './common/buttons';
@import './common/table';
@import './common/helpers';
@import './common/brainy';
@import './common/react-grid-layout';
@import './common/validation-loader';

@import './inputs/custom-multi-select';
@import './inputs/dates';
@import './inputs/radios';
@import './inputs/editor';
@import './inputs/select';
@import './inputs/checkbox';
@import './inputs/form';
@import './inputs/time';
@import './inputs/slider';
@import './inputs/inline-datepicker';
@import './inputs/colorpicker';

@import './plugins/moms';
@import './plugins/calendar';
@import './plugins/control-panel';
@import './plugins/vessels';
@import './plugins/gadget';
@import './plugins/human-resources/time-management';
@import './plugins/human-resources/system';
@import './plugins/human-resources/manage';
@import './plugins/human-resources/approvals';
@import './plugins/human-resources/leaves';
@import './plugins/human-resources/calendars/shared';
@import './plugins/human-resources/calendars/leaves';
@import './plugins/human-resources/calendars/shifts';
@import './plugins/moms';
@import './plugins/meeting-agenda';
@import './plugins/access';

@import './views/auth/components';
@import './views/auth/apparatus';
@import './views/auth/login';
@import './views/auth/intro-page';
@import './views/people';
@import './views/dashboard';
@import './views/landing';
@import './views/legal';
@import './views/qr';
@import './views/newOrganizationForm';
@import './views/reports';
@import './views/innerPages';
@import './views/404';
