/* Font Size */
small,
.small {
  font-size: $size12 !important;
  font-weight: $font-weight-base;
}

.smaller {
  font-size: $size10;
}

.font-weight-400 {
  font-weight: 400;
}

/* Font Weight */
.font-weight-semibold,
.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-black {
  font-weight: 900;
}

/* Line Height */
.line-height-16 {
  line-height: $size16;
}

.line-height-12 {
  line-height: $size12;
}

/* Text colors and transform */
.text-public {
  color: $public-text;
}

.text-muted {
  color: $gray-600 !important;
}

.text-save {
  color: $save-gray;
}

.text-apparatus {
  color: $apparatus;
}

.text-information,
.info-text {
  color: $info-text !important;

  &.activate {
    &:hover {
      color: $green-500 !important;
    }
  }

  &.pending {
    &:hover {
      color: $orange-500 !important;
    }
  }
  &.danger {
    &:hover {
      color: $light-red !important;
    }
  }
}

.text-placeholder {
  color: $placeholder;
}

.text-dark-red {
  color: $dark-red;
}

.text-red,
.text-error {
  color: $red-400;
}

.text-light-green {
  color: $green-500;
}

.text-green {
  color: $green;
}

.text-light-red {
  color: $red-400;
}

.text-danger {
  color: $red-300;
}

.text-transform {
  text-transform: initial !important;
}

.text-brainy-hover {
  &:hover {
    color: $brainy-green !important;
  }
}

.white-space-pre {
  white-space: pre;
}

/* Overflow */
.overflow-y-visible {
  overflow-y: visible !important;
  max-height: initial !important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

/* Flexbox */
.flex-1 {
  flex: 1;
}

/* Widths */
.width-72 {
  width: $size72 !important;
}

.width-48 {
  width: $size48;
}

.width-56 {
  width: $size56;
}

.width-32 {
  width: $size32;
}

.width-16 {
  width: $size16;
}

.width-308 {
  width: 308px;
}

.width-40 {
  width: $size40;
}

.width-auto {
  width: auto !important;
}

.max-width-48 {
  max-width: $size48;
}

.max-height-340 {
  max-height: 340px;
}

.max-width-112 {
  max-width: $size112;
}

.max-width-182 {
  max-width: calc(#{$size160} + #{$size22});
}

.min-height-120 {
  min-height: 120px;
}

.min-width-40 {
  min-width: $size40;
}

.min-width-88 {
  min-width: $size88;
}

/* Heights */
.height-32 {
  height: $size32;
}

.height-64 {
  height: $size64;
}

/* Position */
.right-0 {
  right: 0;
}

/* General */
.img-fluid {
  max-width: 100%;
}

.border-bottom-gray {
  border-bottom: 1px solid $input-border-color;
}

.border-radius-base {
  border-radius: $border-radius-base;
}

.border-color-gray-400 {
  border-color: $gray-400 !important;
}

.border-right-gray {
  border-right: 1px solid $border-color;
}

.border-left-gray {
  border-left: 1px solid $border-color !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.opacity-1 {
  opacity: 1 !important;
}

.opacity-5 {
  opacity: 0.5;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer,
.cursor-pointer {
  cursor: pointer;
}

.pb-05 {
  padding-bottom: $size4 !important;
}
