html {
  scroll-behavior: smooth;
}

.brainy-page {
  .btn-white {
    font-weight: bold;
    font-size: $size18;
    height: $size48;
  }
  .btn {
    border-radius: $border-radius-base;
    border-width: 2px;
  }
}

.public-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 16;

  #logo_square {
    opacity: 0;
    transform: scale(0.6) translateZ(0);
    transition: all 0.35s cubic-bezier(0.6, 0, 0.26, 0.99);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    &.active {
      visibility: visible;
      opacity: 1;
      transform: scale(1) translateZ(0);
    }
  }

  &.mobile {
    transition: all 0.5s;
    height: 60px;
    overflow: hidden;

    &.open {
      height: 100%;
      .nav {
        opacity: 1;
        transition: all 0.5s;
      }
    }

    .navbar-toggler {
      outline: none !important;
    }

    .nav {
      opacity: 0;
      min-height: 35rem;
      z-index: 5;
      position: relative;
    }
    .navbar {
      z-index: 5;
      padding: $size16;
    }

    .nav-item {
      margin-bottom: $size32;
      line-height: 33px;
      font-size: $size18;

      @media screen and (min-width: 370px) {
        margin-bottom: $size40;
        font-size: $size20;
      }

      strong {
        font-weight: 600;
      }

      .burger {
        transition: all 0.5s;
      }
    }

    &.open {
      background: $white;
      .bg {
        position: absolute;
        top: -3rem;
        right: 0;
        width: 100%;
        z-index: 4;
      }
      .bg-2 {
        z-index: 1;
        top: 0rem;
        left: 0rem;
      }

      .bg-3 {
        top: -2rem;
        z-index: 2;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    position: absolute;
  }

  .blub {
    top: $size4;
    left: 28%;
    color: $brainy-green;
  }

  .mobile-menu {
    height: 0px;
    transition: all 0.5s;
    &.open {
      height: 100%;
    }
  }

  .nav-link {
    padding-left: $size28;
    padding-right: $size28;
    @include media-breakpoint-up(xl) {
      padding-left: $size40;
      padding-right: $size40;
    }
  }
  .nav-login {
    padding-left: $size28;
    @include media-breakpoint-up(xl) {
      padding-left: $size40;
    }
  }
}

.public-header-link {
  .blub {
    display: none !important;
    z-index: -1;
  }
  &:hover .blub {
    display: flex !important;
  }
}

.brainy-link {
  position: relative;
  color: $primary;

  &:hover:after {
    content: '';
    background: url('~assets/svg/public/blubs/header_blub.svg') no-repeat center;
    height: 48px;
    width: 30px;
    display: block;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 45%;
  }
}
