.shifts-calendar {
  .fc-timeline {
    .fc-event-container .fc-event.fc-timeline-event:not(.event-transaction) {
      .fc-time,
      .fc-title {
        font-size: $size9;
      }

      &.dark {
        .fc-content {
          color: white;
        }
      }
    }
  }

  .fc-monthly-view {
    .fc-head tbody {
      tr:first-child {
        .fc-today {
          &::after {
            display: none;
          }
        }
      }
    }

    .fc-event-container .fc-event.fc-timeline-event {
      &.not-completed-shifts,
      &.completed-shifts {
        border-color: transparent;
      }
    }

    .fc-body {
      .fc-resource-area {
        .fc-scroller {
          overflow: scroll !important;

          .fc-cell-content .fc-cell-text {
            overflow: hidden;
          }
        }
      }

      .fc-time-area {
        > .fc-scroller-clip {
          .fc-scroller-canvas {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }

  .fc-resourceTimelineWeek-view {
    .fc-head {
      tr:first-child {
        .fc-widget-header {
          border-bottom: none;

          .working-hours {
            font-size: $size10;
            font-weight: 600;
            margin-bottom: -$size16;
            margin-top: $size8;
            padding-top: 7px;
            border-top: 1px dashed $calendar-border-color;
          }
        }

        .fc-time-area {
          tr:first-child {
            .fc-widget-header {
              &:nth-child(6) {
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  width: 1px;
                  height: calc(100% + 17px);
                  top: 0;
                  left: -1px;
                  background-color: $gray-550;
                }
              }
            }
          }
        }
      }

      .fc-time-area {
        .fc-cell-content {
          text-align: center !important;

          .fc-cell-text.fc-sticky {
            position: unset !important;
          }
        }

        tbody {
          > tr:nth-child(2) {
            .fc-widget-header {
              border-top: none !important;
              border-color: transparent;

              .fc-cell-text {
                display: none;
              }
            }
          }
        }
      }
    }

    .fc-body {
      .fc-time-area {
        .fc-bg {
          .fc-slats {
            .fc-widget-content {
              border-color: transparent;

              + .is-saturday {
                border-style: solid;
                border-color: $gray-550 !important;

                + .is-saturday {
                  border-color: transparent !important;
                }
              }
            }
          }
        }
      }
    }

    .fc-timeline-event {
      border-width: 6px !important;
    }
  }

  .fc-monthly-view {
    .fc-widget-header {
      &.fc-time-area {
        tr {
          color: $primary;
          .fc-cell-content {
            padding: 6px 2px !important;
            text-align: center !important;
            .fc-sticky {
              font-weight: 300;
              font-size: $size14;
              display: inline-flex;
              position: unset !important;
            }
          }
          &:first-child {
            height: $size40;
          }
        }
      }

      .fc-today {
        .fc-cell-text,
        .fc-cell-text:first-line {
          font-weight: 700 !important;
        }
      }
    }
  }

  .fc-body {
    .fc-time-area {
      .fc-bg {
        .fc-slats {
          .fc-today:not(.special-day) {
            background-color: rgba($red-400, 0.06) !important;
          }
        }
      }
    }
  }

  .person-resource.is-today {
    background-color: rgba($red-400, 0.06) !important;
  }

  .is-today {
    background-color: rgba($red-400, 0.06);
  }

  .tooltip-handler.tooltip-handler--person {
    height: 16px;
    width: 16px;
    position: absolute;
    display: none;
    left: 4px;
    cursor: pointer;

    &.show {
      display: block;
      background: url('~assets/svg/plugins/human-resources/special_day.svg') no-repeat center !important;

      &:hover {
        + .tooltip.person-tooltip {
          display: block !important;
          opacity: 1;
          left: 24px !important;
          top: 10px !important;
        }
      }
    }
  }

  .leave-icon-container {
    min-height: $size24;
    height: 100%;
    .fc-time,
    .fc-title {
      color: transparent;
    }
  }
  .leave-icon {
    top: $size8;
    height: $size16;
    width: $size16;
    right: $size8;
  }

  .fc-monthly-view {
    .leave-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .leave-icon {
      top: -$size8;
      right: 0;
      position: relative !important;
    }
  }

  .bulk-event {
    box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.3);

    &.fc-event.event-shifts-monthly::before {
      width: calc(100% + 4px);
      left: -4px;
      box-shadow: 2px 2px 0px inset $primary, -2px -2px 0px inset $primary;
      opacity: 1;
    }
  }
}
.shifts-calendar--datepicker {
  .SingleDatePicker {
    .SingleDatePickerInput {
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .DateInput,
    .SingleDatePickerInput,
    .DateInput_input {
      width: 14rem !important;
      height: $size24 !important;
      background: transparent;
      color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.modal-datepicker {
  .SingleDatePicker_picker {
    top: -100px !important;
    left: calc(100% + #{$size12}) !important;
  }
}

@include media-breakpoint-up(lap) {
  .modal-datepicker {
    .SingleDatePicker_picker {
      top: 56px !important;
      left: 0 !important;
    }
  }
}
