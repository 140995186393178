table {
  th {
    font-weight: normal;
    font-style: normal;

    &.with-subheaders {
      flex-wrap: wrap;

      > span {
        width: calc(100% - #{$size12});
        max-width: calc(100% - #{$size12});
        order: 1;

        &.sub-header {
          order: 3;
          font-size: $size10;
        }
      }

      &.sorting {
        &:after {
          order: 2;
          margin-left: 0;
        }
      }
    }
  }

  td {
    font-size: $size14;
  }
}

.table-mm-inner {
  border: 1px solid $border-color;
  background-color: white;
  margin-bottom: $size16;
}

.table-mm {
  width: 100%;
  border: none;
  margin-bottom: 0;

  a:not(.btn) {
    color: darken($light-blue, 15%);

    &:hover {
      color: $light-blue;
    }
  }

  th {
    color: $dark-red;

    &.sorting {
      &:after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url('~assets/svg/general/chevron-left.svg') no-repeat center;
        margin-left: $size16;
        opacity: 0.2;
      }

      &.sorting--active {
        &:after {
          opacity: 1;
        }
      }

      &.sorting--asc {
        &:after {
          transform: rotate(90deg);
        }
      }

      &.sorting--desc {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .col {
    padding-left: $size8;
    padding-right: $size8;
  }

  td,
  th {
    &.col {
      border-bottom: 1px solid $border-color;
    }
  }

  tr {
    &:last-child {
      td {
        &.col {
          border-bottom: none;
        }
      }
    }
  }

  th,
  td {
    padding: $size16;
    vertical-align: middle;
    border-top: none;

    &:first-child {
      padding-left: $size32;
    }

    &:last-child {
      padding-right: $size32;
    }

    &.split-col {
      position: initial;

      &.split-col--colored {
        background: #f1f1f1;
        box-shadow: 0px 1px 0px 0px #fff;
      }

      @include media-breakpoint-up(xl) {
        position: relative;

        &.split-col--colored {
          background: none;
        }
      }
    }

    &.col:not(.split-col) {
      position: initial;
    }
  }

  td {
    word-break: break-word;
  }

  tr {
    &:last-child {
      border-bottom-width: 0;

      td.split-col.split-col--colored {
        box-shadow: none;
      }
    }
  }
}

.table-sticky {
  overflow-x: auto;

  .table-mm {
    min-width: 1200px;

    th,
    td {
      &.sticky {
        position: sticky;
        background-color: rgb(255, 255, 255);
      }
    }

    td {
      &.sticky {
        &:hover {
          z-index: 1;
        }
      }
    }
  }

  @include media-breakpoint-up(hd) {
    overflow-x: initial;

    .table-mm {
      min-width: initial;

      th,
      td {
        &.sticky.last-sticky {
          border-right: none !important;
        }
      }

      .border-right-gray {
        border-right: 0;
      }

      .border-left-gray {
        border-left: 0 !important;
      }
    }
  }
}

.split-bg-wrapper {
  top: 0;
  left: 0;
  display: none;

  .split-bg {
    box-shadow: -3px 0 28px 0 rgba(181, 181, 181, 0.4);
    background: #f1f1f1;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      width: 32px;
      height: 34px;
      background: url('~assets/svg/general/binder.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      top: -22px;
      left: 4px;
    }
  }

  @include media-breakpoint-up(xl) {
    display: block;
  }
}

.pagination {
  .page-link {
    border-radius: 0 !important;
    border-color: $border-color;
  }
}

.pagination-bottom {
  .pagination {
    .page-item {
      &:first-child,
      &:last-child {
        .page-link {
          font-size: 2rem;
          line-height: 23px;
          padding: 0;
          height: $size32;
          width: $size32;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}

.pagination-top-hide-total-show {
  .pagination,
  .pagination-current,
  .pagination-previous,
  .pagination-next {
    display: none;
  }
}

.cursor {
  &-grab {
    cursor: grab;
  }
  &-grabbing {
    cursor: grabbing;
  }
}
