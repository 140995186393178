.form-group {
  &.form-group--search {
    border-bottom: 1px solid $input-border-color;
    width: 14rem;

    .form-control {
      border: none;
      padding-left: 0;
      height: $size32;
    }

    &:after {
      content: '';
      background: url('~assets/svg/general/search.svg');
      display: block;
      width: $size16;
      height: $size16;
    }
  }

  &.form-group--horizontal {
    display: flex;
    align-items: center;

    .form-label,
    .slide-label {
      margin-bottom: 0 !important;
      min-width: $size112;
      max-width: $size112;
      margin-right: $size16 !important;
      text-transform: uppercase;

      + .form-field {
        + .form-error {
          left: $size128;
        }
      }
    }

    .form-error {
      left: 0;
      bottom: 0;
      transform: translateY(100%);
      width: calc(100% - #{$size120});
    }
  }

  &.form-group--padding {
    .form-control {
      padding-left: $size16;
      padding-right: $size16;
    }
  }

  &.form-group--border {
    .form-control {
      border: 1px solid $border-color;
      border-radius: 1px;
      height: $size40;
    }
  }

  small.extra {
    font-size: 9px;
    display: block;
  }
}

.form-control {
  font-weight: 300;
  border-bottom: 1px solid $input-border-color;

  &:disabled {
    background-color: transparent;
    @extend .disabled;
  }
}

textarea.form-control {
  border: 1px solid $border-color !important;
  resize: none;
  border-radius: 1px;
  padding: $size8;
  background: #fff !important;
}

.edit-height {
  min-height: 2.5rem;
}

.form-label {
  font-weight: 400;
  color: $green;
  line-height: 19px;
}

.password-input {
  position: relative;

  &.with-value {
    .password-input__toggle {
      opacity: 1;
    }
  }

  &__toggle {
    position: absolute;
    right: 0;
    top: $size28;
    padding: 0;
    transition: all 400ms ease-in-out;
    opacity: 0;
    height: $size32 !important;
    width: $size20;
    color: $text-muted;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 4.62a13.21 13.21 0 019 3.53 15.68 15.68 0 012.18 2.42l.29.4c.21.31.36.56.45.72a.62.62 0 010 .62 11.23 11.23 0 01-.74 1.12A15.68 15.68 0 0121 15.85a13.24 13.24 0 01-18 0 15.68 15.68 0 01-2.18-2.42L.53 13c-.21-.31-.36-.56-.45-.72a.62.62 0 010-.62 11.23 11.23 0 01.74-1.12A15.68 15.68 0 013 8.15a13.21 13.21 0 019-3.53zm0 2.89A4.49 4.49 0 1016.49 12 4.49 4.49 0 0012 7.51zm0 2.69a1.93 1.93 0 11-1.93 1.93A1.94 1.94 0 0112 10.2z' fill='%23afafaf' fill-rule='evenodd'/%3E%3C/svg%3E");

    &:hover,
    &.is-visible {
      background-image: url("data:image/svg+xml,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 4.62a13.21 13.21 0 019 3.53 15.68 15.68 0 012.18 2.42l.29.4c.21.31.36.56.45.72a.62.62 0 010 .62 11.23 11.23 0 01-.74 1.12A15.68 15.68 0 0121 15.85a13.24 13.24 0 01-18 0 15.68 15.68 0 01-2.18-2.42L.53 13c-.21-.31-.36-.56-.45-.72a.62.62 0 010-.62 11.23 11.23 0 01.74-1.12A15.68 15.68 0 013 8.15a13.21 13.21 0 019-3.53zm0 2.89A4.49 4.49 0 1016.49 12 4.49 4.49 0 0012 7.51zm0 2.69a1.93 1.93 0 11-1.93 1.93A1.94 1.94 0 0112 10.2z' fill='%231c2a3b' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
  }
}
