.people-list {
  .avatar {
    border-radius: 50%;
    object-fit: cover;
    min-width: $size32;
    max-width: $size32;
    height: $size32;
  }
  .min-width {
    min-width: 21rem;
  }
  .min-200 {
    min-width: 12.5rem;
  }
  .min-180 {
    min-width: 11.25rem;
  }
  .min-170 {
    min-width: 10.62rem;
  }
  .default-pto {
    color: #c2c2c2;
  }

  .table-sticky {
    .table-mm {
      min-width: 1440px;
      @include media-breakpoint-up(hd) {
        min-width: initial;
      }
    }
  }
}

.pto-history {
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    border: 1px solid $primary;
    height: 100%;
    left: -$size4;
    top: $size8;
  }

  .history-text {
    position: relative;

    &:after {
      content: ' ';
      height: $size8;
      width: $size8;
      background: $primary;
      position: absolute;
      left: -7px;
      top: $size4;
      border-radius: 50%;
    }
  }
}

.pto-modal-reset {
  position: absolute;
  top: $size34;
  right: $size48;
  color: $orange;
  cursor: pointer;
  display: flex;
}

.default-settings-message {
  position: absolute;
  top: $size34;
  right: $size48;
  color: $green;
  display: inline-block;
}

.max-80 {
  max-width: 80%;
}
