@import '../../redactor/redactor';

.redactor-box {
  border: 1px solid $border-color;
  background: #fff;

  .redactor-toolbar-wrapper {
    border-bottom: 1px solid $border-color;

    .redactor-toolbar {
      z-index: 0;
    }

    .re-button {
      box-shadow: none;
      margin: 0;
      border-radius: 0;
      padding: $size8;
      height: $size32;

      &:hover:not(.redactor-button-active) {
        background: none;
        color: $dark-red;
      }
    }
  }

  .redactor-in {
    padding: $size16;
    font-size: $size14;
    a {
      color: $light-blue;
    }
  }
}

.redactor-dropdown.redactor-dropdown-link {
  font-family: $font-family-base;

  a {
    color: $body-color;
    font-size: $size14;

    &:hover {
      color: $dark-red !important;
      background: none !important;
    }
  }
}
