.vessel-position-form {
  .form-group {
    margin-bottom: $size24;
  }

  .form-label {
    margin-bottom: $size8;
    text-transform: uppercase;
  }

  .vessel-position-section {
    border: 1px solid $border-color;

    .vessel-position-header {
      font-size: $size14;
      background: $border-color;
      line-height: 17px;
      height: $size40;
    }
  }
}

.port-generals {
  .fancy-checkbox {
    margin-bottom: $size8;
  }
}
