.checkbox {
  cursor: pointer;

  .uncheckedBox {
    display: inline-block;
    min-width: $size16;
    max-width: $size16;
    height: $size16;
    border: 1px solid $input-border-color;
  }

  .checkedBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: $size16;
    max-width: $size16;
    height: $size16;
    line-height: $size16;
    text-align: center;
    background-color: black;
    border: 1px solid black;
    color: white;
    font-size: $size8;
  }

  &.optional-participant {
    .uncheckedBox,
    .checkedBox {
      border: none;
    }

    .uncheckedBox {
      background: url('~assets/svg/general/optional_participant_solid.svg') no-repeat center;
    }

    .checkedBox {
      background: url('~assets/svg/general/optional_participant_line.svg') no-repeat center;

      > .svg-wrap {
        display: none !important;
      }
    }
  }
}

.fancy-checkbox {
  background-color: $gray-700;
  width: $size32;
  height: $size32;
  line-height: $size32;
  text-align: center;
  color: white;
  font-size: $size14;
  font-weight: 600;
  transition: 0.3s all;
  cursor: pointer;

  &.checked {
    background-color: $light-red;
  }
}
