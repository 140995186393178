.new-organization-form {
  .form-header {
    color: $public-text;
    font-size: $size26;
    line-height: 33px;
    font-weight: 400;
  }

  .organization-input {
    label {
      color: $public-text;
      font-size: $size16;
      font-weight: bold;
      line-height: $size20;
      margin-bottom: $size16;
    }
  }
  .react-select__menu {
    border: 1px solid $text-muted;
    border-radius: 3px;

    .react-select__menu-list {
      padding: 0;
      max-height: 328px;
    }

    .react-select__option {
      color: $public-text;
      font-size: $size16;
      line-height: $size20;
      padding: $size8 $size16;

      &:hover {
        background-color: $gray-350 !important;
        color: $public-text !important;
        cursor: pointer;
      }
    }
    .react-select__option.react-select__option--is-focused {
      color: $public-text !important;
    }
  }

  .logo-upload {
    .image-title {
      color: $public-text;
      font-size: $size16;
      font-weight: bold;
      line-height: $size20;
    }
    .preview {
      margin-right: $size40;
      background-color: $body-bg;
      border: 1px dashed $public-text;
      border-radius: 3px;
    }

    .flex-column {
      color: $public-text;
      font-size: $size16;
      font-weight: 400;
      line-height: $size20;

      .text-muted {
        color: $text-muted !important;
        font-weight: 200;
        font-size: $size16;
        line-height: $size20;
      }
    }
  }

  .btn-create {
    color: $brainy-green;
    min-height: $size40;
    min-width: $size112;
    border-color: $brainy-green;
    font-size: $size14;
    font-weight: bold;
    line-height: $size18;
    text-align: center;

    &:hover {
      color: $body-bg;
      border-color: $brainy-green;
      background-color: $brainy-green;
    }
  }

  .btn-cancel {
    color: $red-200;
    font-size: $size14;
    font-weight: bold;
    line-height: $size18;
    text-align: right;
  }

  .logo-text {
    height: $size20 !important;
  }
}
