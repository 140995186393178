.sidebar-widths {
  width: map-get($sidebar-breakpoints, landscape);

  @include media-breakpoint-up(lap) {
    width: map-get($sidebar-breakpoints, lap);
  }

  @include media-breakpoint-up(hd) {
    width: map-get($sidebar-breakpoints, hd);
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: $headerH;
  height: calc(100vh - #{$headerH});
  @extend .sidebar-widths;

  .sidebar-item {
    padding: $size8 $size24;
    position: relative;
    font-size: $size14;
    display: block;
    color: $primary;
    transition: none;
    cursor: pointer;

    svg {
      fill: currentColor !important;

      * {
        transition: all 0s;
      }
    }

    &.is-active:not(.sidebar-item--create) {
      font-weight: 400;
    }

    &.is-active:not(.sidebar-item--create),
    &:hover {
      &.default-colored {
        color: $dark-red;
      }
    }

    &.sidebar-item--create {
      @extend .d-inline-flex;
      @extend .btn;
      @extend .btn-green;
      line-height: 1.25rem;
      padding: $size4 $size16;
      padding-left: $size56;
      font-weight: normal;
      margin-top: $size16;
      margin-bottom: $size8;

      .svg-wrap {
        svg {
          margin-right: $size8 !important;
          height: 12px !important;
          width: 12px !important;
        }
      }
    }

    &.is-open {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }

  .arrow {
    position: absolute;
    right: $size8;
    top: calc(50% - 5px);
    transform: rotate(0deg);
    transition: 0.3s all;
    cursor: pointer;
    // width: $size16;
    // height: $size16;
  }

  .submenu {
    background-color: $gray-300;
  }

  .sidebar__app-version {
    position: absolute;
    left: $size24;
    bottom: $size16;
    color: $primary;
    font-size: $size10;
  }
}

.right-sidebar {
  border-left: 1px solid $gray-600;
  position: fixed;
  right: 0;
  bottom: 0;
  top: $headerH;
  height: calc(100% - #{$headerH});
  width: map-get($rightSidebar-breakpoints, landscape);

  @include media-breakpoint-up(lap) {
    width: map-get($rightSidebar-breakpoints, lap);
  }

  @include media-breakpoint-up(hd) {
    width: map-get($rightSidebar-breakpoints, hd);
  }

  .draggable-widget span {
    font-weight: 300;
  }

  .toolbox {
    border-bottom: 1px solid $gray-600;

    .title {
      font-size: 0.7rem;
    }

    > .draggable-widget {
      .btn-widget {
        background-color: white;
        border-width: 0;
        cursor: pointer;
        margin-bottom: $size16;
        font-size: $size14;
        line-height: 17px;
        color: $primary;

        .svg-wrapper {
          margin-right: $size16;
        }
      }

      &:last-child {
        .btn-widget {
          margin-bottom: 0;
        }
      }
    }
  }

  .timeline-wrapper {
    flex: 1;
    overflow-y: auto;
    @extend.scrollbar;

    > .timeline-log {
      border-left: 1px solid $gray-600;
      font-size: $size14;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: $size8;
        width: $size8;
        background-color: $gray-600;
        border-radius: 100%;
        top: 0;
        left: -4px;
      }

      .timeline-date {
        font-size: $size12;
        line-height: 1;
        margin-bottom: $size4;
        color: $gray-600;
      }
    }

    .timeline-action--user {
      font-weight: 400;
    }
  }
}
