.leaves-calendar {
  .fc-widget-header.fc-today {
    .fc-cell-text,
    .fc-cell-text:first-line {
      font-weight: 700 !important;
    }
  }

  .default-calendar .fc-timeline {
    .fc-head {
      .fc-resource-area {
        th.fc-widget-header {
          .fc-expander-space {
            display: none;
          }
        }
      }
    }

    .fc-body {
      .SingleDatePicker .SingleDatePickerInput {
        height: unset !important;
      }

      .fc-widget-content {
        .popover {
          max-width: 360px;
          width: 360px;
          .form-group {
            .SingleDatePickerInput {
              padding-right: $size8;
            }

            &--field-popover-hours {
              .form-control {
                max-height: $size30;
                padding: 0 $size14;
                max-width: $size52;
              }
            }
          }
        }
      }

      .fc-event-container {
        .fc-event {
          &.h-100 {
            height: calc(100% - 2px) !important;
          }

          &.h-50 {
            height: calc(50% - 2px) !important;
          }
        }
      }
    }
  }

  .hr-calendar {
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: $size14;
    }

    .fc-timeline {
      .fc-body {
        .fc-event-container {
          height: 100% !important;
          z-index: auto !important;
          &.active {
            z-index: 100;
          }
        }
        .fc-event {
          margin: 0;
          &.top-50 {
            top: 50% !important;
          }

          &.leave-rejected:after {
            content: '';
            height: 6px;
            width: 100%;
            background: #000;
          }

          &.leave-deleted {
            background: linear-gradient(
                103deg,
                transparent 45%,
                rgb(255, 255, 255) 45%,
                rgb(255, 255, 255) 52%,
                transparent 55%,
                transparent 100%,
                rgb(255, 255, 255) 100%,
                rgb(255, 255, 255) 100%
              )
              0% 0% !important;
            margin: 1px;
            left: 800px;
            right: -820px;
            top: 0px;
            background-size: 18px 58.66px;
            opacity: 0.3 !important;
          }
        }
      }
    }

    .fc-body .fc-today:not(.special-day) {
      background-color: rgba($red-400, 0.06) !important;
      position: relative;
    }

    .fc-widget-header {
      &.fc-time-area {
        tr {
          color: $primary;
          &:first-child {
            height: $size40;

            .fc-cell-text {
              display: inline-flex;
              font-size: $size14;
              font-weight: 300;
            }
          }

          &:not(:first-child) {
            .fc-today {
              position: relative;
            }
          }

          &:nth-child(2) {
            .fc-widget-header {
              padding-top: $size16;
              border-bottom: none;
              .fc-cell-content {
                text-align: center !important;
                padding: 0;
                width: 100%;
              }
              .fc-cell-text {
                font-size: $size10;
                padding: 0;
                text-align: center;
                width: 100%;
              }
            }

            // .fc-today {
            //   color: $red-400;
            //   font-weight: bold;
            // }
          }
          &:last-child {
            .fc-widget-header {
              padding-bottom: $size16;
              border-top: none;
              .fc-cell-content {
                text-align: center !important;
                padding: 0;
                width: 100%;
              }
              .fc-cell-text {
                font-size: $size10;
                padding: 0;
                text-align: center;
                width: 100%;
                visibility: collapse;
                font-weight: 300;
                padding-left: 3px;
                &::first-letter {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-options {
    .square-request {
      height: $size18;
      width: $size18;
      background: $info-text;

      &.pending {
        background: repeating-linear-gradient(
            -45deg,
            #fff,
            #fff 5%,
            $info-text 5%,
            $info-text 10%,
            #fff 10%
          )
          top left;
      }

      &.rejected {
        box-shadow: inset 0px 6px 0px 0px #000;
      }

      &.deleted {
        background: linear-gradient(
            135deg,
            transparent 45%,
            rgb(255, 255, 255) 45%,
            rgb(255, 255, 255) 52%,
            transparent 55%,
            transparent 100%,
            rgb(255, 255, 255) 100%,
            rgb(255, 255, 255) 100%
          )
          0% 0% !important;
        opacity: 0.3;
        background-size: 18px 58.66px;
        background-color: #8f8f8f !important;
      }
    }
  }

  .fc-button-active {
    color: $light-blue !important;
    border-color: $light-blue !important;
    z-index: 1;
  }

  .month-end,
  .fc-head table tr:first-child th {
    border-right: 1px solid rgba(143, 143, 143, 0.6) !important;
  }

  .abbreviation--small {
    margin-top: 6px;
  }
}

.active-tab-leaves {
  width: $size56;
  height: 2px;
  left: 0px;
  bottom: $size10;
  position: absolute;
  background: $dark-red;
  transition: all 0.5s ease 0s;
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.21);

  &.indicator-blue {
    background: $blue;
  }
}
