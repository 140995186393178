.people-list-table {
  .table-sticky {
    overflow-x: auto;
  }

  @include media-breakpoint-up(standardhd) {
    .table-sticky {
      overflow-x: visible;
    }
  }

  .email-col {
    min-width: 16rem;
  }

  @include media-breakpoint-up(standardhd) {
    .email-col {
      min-width: initial;
    }
  }

  .fixed-width-cell {
    min-width: $size208;
    max-width: 18.75rem;
  }

  @include media-breakpoint-up(standardhd) {
    .fixed-width-cell {
      min-width: initial;
      max-width: initial;
    }
  }

  .card-col {
    min-width: $size54;
  }
}

.profile {
  .profile-tabs-wrapper {
    overflow: visible;

    @include media-breakpoint-up(xl) {
      overflow: hidden;
    }

    .horizontal-slider {
      max-width: $size208;
      min-width: $size144;
      width: 100%;
    }

    .person-shifts-slider {
      min-width: 380px;
    }
  }
  .profile-human-resources {
    .profile-shifts-buttons {
      max-width: 200px;
    }
    .remote-work {
      background-color: #fff;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      max-width: 26rem;
      margin-bottom: $size64;
      margin-top: $size16;
      padding: $size16 $size22 $size16 $size24;

      .become-available {
        border: 1px solid $info-text;
        border-radius: 1px;
        color: $info-text;
        padding: $size8 $size16;
        font-size: $size12;
        line-height: 17px;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        min-width: 8.5rem;
        &:hover {
          color: $green;
          border-color: $green;
          background-color: transparent;
        }
        &.end-shift {
          color: $green;
          border-color: $green;
          background-color: transparent;
          &:hover {
            color: $info-text;
            border-color: $info-text;
          }
        }
      }
    }
  }

  .details {
    width: 100%;

    .user-img {
      width: 12.375rem;
      height: 12.375rem;
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(xl) {
    .details {
      width: 21.75rem;

      .user-img {
        width: 21.75rem;
        height: 21.75rem;
      }
    }
  }
}

.person-form {
  max-width: 32rem;
}

.orders-soon {
  background-color: rgba(255, 255, 255, 0.69);

  .svg-wrap {
    color: $light-blue;
  }

  span {
    font-size: 1.75rem;
    font-weight: bold;
    color: $info-text;
    opacity: 0.42;
    text-align: center;
  }
}

.bordered-label {
  border: 1px solid $green;
  font-size: 10px;
  border-radius: 3px;
  padding: $size4 $size8;
  background-color: rgba(255, 255, 255, 0.75);
}

.profile-access-in-out-table {
  min-height: 500px;
}

.access-points-dropdown {
  margin-left: $size16;

  @include media-breakpoint-up(sm) {
    margin-left: 0px;
  }
}

.person-profile__apparatus {
  .form-control {
    &:disabled,
    &[readonly] {
      background-color: #fff;
    }
  }

  .title-decorated {
    &::after {
      background-color: $apparatus;
    }
  }

  &-box {
    padding: $size16;
    background-color: #fff;
    border: 1px solid $border-color;
  }
}

.person-booking-warning {
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  &__box {
    background-color: rgba($orange, 0.07);
    border-radius: 4px;
  }

  &__info {
    font-size: $size12;
  }

  .c-icon {
    margin-bottom: $size4;
  }
}
