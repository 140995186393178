ul,
ol {
  li {
    line-height: $size24;
  }
}

b,
strong {
  font-weight: bold;
}

.logo-image {
  object-fit: contain;
  width: $size48;
  height: $size32;
}

.section-title {
  margin-top: 0;
  font-size: $size24;
  margin-bottom: $size32;
  font-weight: 300;
}

.section-subtitle {
  font-size: $size18;
  margin-bottom: $size16;
}

.or-text {
  color: $gray-600;
  line-height: 1;
  padding: 0 $size16;
  font-weight: normal;
}

.section {
  border: 1px solid $border-color;
  background-color: white;
  border-radius: 3px;

  .checkbox {
    font-size: $size14;
    // line-height: 15px;
  }
  .uncheckedBox,
  .checkedBox {
    margin-right: 0.5rem !important;
  }
}

.section-header {
  padding: $size8 $size18;
  font-size: $size14;
  font-weight: 400;

  .toggler {
    transition: 0.3s all;
    &.open {
      transform: rotate(180deg);
    }
  }
}

.section-content {
  padding: $size16 $size18;
  border-top: 1px solid $border-color;
}

.add-person-form {
  background: $gray-300;

  .add-person-form--info {
    color: $light-red;
  }
}

.suggestions-input {
  font-size: $size14;
  position: relative;

  input {
    display: block;
    width: 100%;
    outline: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 4px 0;

    li {
      cursor: pointer;
    }
  }

  .suggestions-dropdown {
    // box-shadow: 4px 6px 32px -3px #9e9e9e;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    z-index: 10;
  }

  .new-key {
    cursor: pointer;
    padding: 4px 20px;

    &:hover {
      background-color: $border-color;
    }
  }

  .arrow {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: rotate(0) translateY(-50%);
    transition: 0.3s all;
    transform-origin: 50% 0;
    cursor: pointer;

    &.open {
      transition: 0.3s all;
      transform: rotate(180deg) translateY(-50%);
    }
  }
}

.remove-text {
  cursor: pointer;
  color: $light-red;
  font-size: $size12;
  font-weight: 400;
}

.input-as-label {
  position: relative;

  &:after {
    content: ':';
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.scale-anim {
  animation-name: scale;
  transform-origin: 0 0;
  animation-duration: 0.6s;
}

@keyframes scale {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 1000px;
  }
}

.drop-area {
  padding: $size20 0;
  transition: 0.3s all;
  .inner {
    text-align: center;
    transition: 0.3s all;
    font-size: 0;
    padding: 0;
    color: white;
    opacity: 0;
  }

  &.active {
    padding: $size40 0;

    .inner {
      opacity: 1;
      font-size: $size24;
      padding: $size32 0;
      border: 1px dashed $dark-red;
      color: $dark-red;
    }
  }
}

.image-uploader-wrapper {
  .preview-img {
    width: 96px;
    height: 96px;
    object-fit: contain;
    margin-right: $size16;
    background: $border-color;
  }

  .preview {
    width: 96px;
    height: 96px;
    background: $border-color;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    border: 1px dashed $input-border-color;
    margin-right: $size16;

    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      border-style: solid;
    }
  }

  .remove {
    font-size: $size12;
    margin-bottom: $size8;
    color: $dark-red;
    cursor: pointer;
  }

  .UploadHandler {
    display: none;
    svg {
      fill: currentColor;
    }
  }

  input {
    display: none;
    svg {
      fill: currentColor;
    }
  }
}

.cta {
  font-size: 14px;

  a {
    color: $light-red;
  }
}

.abbreviation {
  min-width: $size32;
  max-width: $size32;
  height: $size32;
  line-height: $size32;
  text-align: center;
  border-radius: 100%;
  background-color: $gray-700;
  color: white;
  font-size: $size10;
  font-weight: 700;

  &.abbreviation--small {
    min-width: $size8;
    max-width: $size8;
    height: $size8;
    font-size: 0;
  }
}

.image-popover {
  display: flex;
  position: relative;

  .image-popover-preview {
    position: fixed;
    display: none;
    width: $size152;
    height: $size152;
    z-index: 10;
    border: 1px solid $border-color;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.16);
    background: #fff;

    &.is-loading {
      background: url('~assets/svg/general/loader.svg') #fff no-repeat center;

      .preview-image {
        display: none;
      }
    }

    .preview-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .image-popover-preview {
      display: block;
    }
  }
}

.title-decorated {
  display: flex;
  align-items: center;

  span {
    font-size: $size18;
    margin-right: $size32;
  }

  &:after {
    content: '';
    background-color: $dark-red;
    height: $size4;
    flex-grow: 1;
  }
}

.tabs {
  .tabs-buttons {
    list-style: none;
  }
}

.tabs-wrapper {
  &.tabs-wrapper--cards {
    .tabs-buttons {
      position: relative;

      .cards-active-indicator {
        width: $size56;
        height: 2px;
        left: 0px;
        bottom: -2px;
        position: absolute;
        background: $dark-red;
        transition: all 0.5s ease 0s;
        box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.21);

        &.indicator-blue {
          background: $blue;
        }
      }

      .card-button {
        &.active,
        &:hover {
          color: $dark-red;
        }

        svg * {
          transition: none;
        }

        &::before {
          display: none;
        }
      }

      .tab-title {
        width: 180px;
        line-height: 28px;
        padding-bottom: 4px;
        font-size: $size18;
        cursor: pointer;
      }
    }
    .container-sm {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
    }
  }
}

.simple-tab {
  position: relative;
  cursor: pointer;
}

.profile-tab-indicator {
  width: $size56;
  height: 3px;
  top: $size32;
  position: absolute;
  background: $light-blue;
  transition: all 0.5s ease 0s;
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.21);
}

.simple-popover {
  position: relative;
  font-size: $size12;

  .popover-title {
    border-bottom: 1px solid $black;
    padding-bottom: $size4;
  }

  .popover-body {
    display: none;
    transition: 0.3s all;
    position: absolute;
    left: -$size16;
    top: 50%;
    transform: translate(-100%, -50%);
    border: 1px solid $border-color;
    min-width: 180px;
    background-color: white;
    z-index: 10;
    padding: $size18;
    box-shadow: 4px 6px 32px -3px $shadow;
  }

  .popover-main {
    padding: $size18 0 0;
    > div {
      margin-bottom: $size8;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    .popover-body {
      display: block;
    }
  }
  &.comment-popover {
    .popover-body {
      transform: translate(35%, -50%);
      right: -$size16;
      top: -$size24;
    }
  }

  &.mom-edit-popover {
    .popover-body {
      transform: translate(10%, -50%);
      left: 0;
      top: $size16;
    }
  }
}

.person-avatar {
  object-fit: cover;
  max-width: $size32;
  min-width: $size32;
  height: $size32;
}

.popover {
  box-shadow: 0 2px 4px 1px rgba(28, 42, 59, 0.1);
  border-radius: 1px;
  border: 1px solid $border-color;
  font-size: $size14;
  line-height: 19px;
  width: 15rem;
  padding: $size16;

  .popover-header {
    position: relative;
    background: none;
    border: 0;
    padding: $size8 0;
    color: $green;
    font-weight: 400;
    font-size: $size14;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 90%;
      left: 0;
      top: 100%;
      margin-left: 0;
      border: none;
      height: 1px;
      background: $input-border-color;
    }
  }

  .popover-body {
    padding: $size16 0 0 0;
    font-weight: 300;
  }

  .arrow {
    display: none;
  }
}

.groups-form {
  .form-group.form-group--horizontal {
    .react-select + .form-error {
      left: 0;
    }
  }
}

.card-button {
  width: $size56;
  height: $size40;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  color: $info-text;
  position: relative;
  margin-right: $size4;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $size4;
    background-color: $calendar-border-color;
    transition: 0.3s all;
  }

  &.active {
    color: $primary;

    &:before {
      background-color: $red-600;
      box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.21);
    }
  }
}

.expandable-section {
  background-color: white;
  font-size: $size12;
  border: 1px solid $border-color;
  margin-bottom: 1px;
  position: relative;

  .form-label,
  .table thead th,
  .checkbox {
    font-size: $size14;
  }

  .radio-wrapper {
    line-height: $size20;
  }

  .toggler {
    transition: 0.4s all;
    transform: rotate(0deg);
    cursor: pointer;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .input-standar,
  .select-wrapper {
    width: 180px;
  }

  .react-select__placeholder,
  .react-select__single-value,
  .form-control,
  .custom-multi-select .label,
  .small,
  .custom-multi-select .checkbox .text,
  .react-select__multi-value__label {
    font-size: $size12 !important;
    line-height: 17px;
  }

  &.no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .header-section,
    .collapsed {
      padding-left: $size16;
      padding-right: $size40;
    }

    &.open {
      padding-bottom: 0 !important;

      .expanded > div {
        padding-bottom: 0 !important;
      }
    }
  }
}

.settings {
  .custom-multi-select .label {
    font-size: $size12;
  }
}

.hidden-collapsing.collapse > div {
  transition: opacity 0.3s;
}

.hidden-collapsing.collapsing > div {
  opacity: 0;
}

.saved-success {
  opacity: 0;
  transition: all 0.2s ease-in-out;

  &.visible {
    opacity: 1;
  }
}

.scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(33, 34, 36, 0.18);
    border-radius: 9px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9px;
  }

  &.green {
    &::-webkit-scrollbar-thumb {
      background-color: $brainy-green;
    }
  }
}

.color-select {
  width: $size112;

  .react-select__menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $size16 !important;
  }

  .color-option {
    flex: 1 0 33%;
    min-width: $size12;
    min-height: $size12;
    max-height: $size12;
    margin-bottom: $size14;
    line-height: 1;

    > div {
      width: $size12;
      height: $size12;
      display: inline-block;
    }

    &:nth-child(3n + 2) {
      text-align: center;
    }

    &:nth-child(3n + 3) {
      text-align: right;
    }

    &:nth-last-child(-n + 3) {
      margin-bottom: 0 !important;
    }
  }

  .selected-color {
    height: 3px;
    width: 60px;
  }
}

.icons-select {
  width: $size72;

  .react-select__menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 !important;
  }

  .icons-select-option {
    flex: 1 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $size12 0;
    color: $gray-700;
    cursor: pointer;

    &.selected {
      color: $primary;
    }
  }

  .react-select__value-container {
    display: flex;
    justify-content: center;
  }
}

.profile-type-form {
  border: 1px solid $border-color;

  .title {
    border-bottom: 1px solid $border-color;
    font-size: $size16;
  }

  .actions {
    border-top: 1px solid $border-color;
  }
}

.is-downloading {
  position: relative;
  width: 100%;
  height: 100%;

  .svg-wrap {
    position: absolute;
    top: calc(50% - #{$size16});
    left: calc(50% - #{$size16});

    svg {
      width: $size32 !important;
      height: $size32 !important;
    }
  }
}

.dropdown {
  .dropdown-menu {
    border-radius: 1px;
    border: 1px solid $dropdown-border;
    box-shadow: 0 2px 4px 1px rgba(28, 42, 59, 0.1);
    padding: 0 $size16;

    .dropdown-item {
      font-weight: $font-weight-base;
      padding: $size8;
      outline: none !important;

      &:first-child {
        padding-top: $size16;
      }

      &:last-child {
        margin-top: $size8;
        padding-top: $size16;
        padding-bottom: $size16;
        border-top: 1px solid $dropdown-border;
      }
    }
  }
}

.tag {
  color: #fff;
  font-size: $size10;
  line-height: $size14;
  font-weight: 700;
  padding: 1px $size8;
  margin: $size2 $size4 $size2 0;
  border-radius: 1px;

  &.default {
    background: $green;
  }

  &.grey-bg {
    background-color: $gray-400 !important;
  }
}

.group-wrapper {
  padding: $size16;
  background: #eaebec;

  &:empty {
    padding: 0;
    background: initial;
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  width: $size48;
  height: $size48;
  position: fixed;
  top: calc(50% - #{$size48});
  left: calc(55% - #{$size24});

  .loader-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $primary;
    background: -moz-linear-gradient(left, $primary 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, $primary 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, $primary 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, $primary 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, $primary 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);

    &:before {
      width: 50%;
      height: 50%;
      background: $primary;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }

    &:after {
      background: $page-background;
      width: 80%;
      height: 80%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.bullet-icon {
  width: $size8;
  height: $size8;
  border-radius: 100%;
}

.curved-tabs {
  padding: $size12 $size32 0 $size32;
  border-bottom: 1px solid $gray-350;
  margin-bottom: 0 !important;

  > div {
    position: relative;
    height: 50px; //magic number
    width: 80px; //magic number

    &.active {
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 3px;
        transform: translateX(-50%);
        width: 114px;
        height: 50px;
        background-image: url('~assets/svg/legal/curve.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
        z-index: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
    }
  }

  .svg-wrap {
    position: relative;
    top: 16px;
    z-index: 2;
    display: flex;
    justify-content: center;
  }
}

.fancy-tabs {
  margin-bottom: $size22 !important;

  > div {
    font-size: $size16;
    font-weight: 600;
    padding: 0 $size32;
    position: relative;
    color: $gray-350;

    span {
      position: relative;
      z-index: 2;
    }

    &.active {
      color: $black;
      &:before {
        content: '';
        width: 32px;
        height: 36px;
        background-image: url('~assets/svg/legal/pencil_stroke.svg');
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 34px;
        z-index: 1;
      }
    }

    &:not(.active) {
      cursor: pointer;
    }
  }
}

body.blur.modal-open {
  #root {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
  .modal-backdrop {
    background-color: initial !important;
  }
}

.type-bullet {
  border-radius: 50%;
  height: $size8;
  min-width: $size8;
  max-width: $size8;
}

.user-panel {
  .btn-toggle {
    max-width: 240px;
  }

  .avatar {
    width: $size32;
    height: size32;
    border-radius: 50%;
    object-fit: cover;
  }

  .dropdown-menu {
    width: 240px;
  }

  .panel-group {
    border-bottom: 1px solid $border-color;
    padding: $size8 0;
    max-height: 205px;
    overflow-y: auto;

    &:last-child {
      border-bottom: 0;
    }
  }

  .panel-item {
    padding: $size8 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .panel-group-title {
    padding: $size8 0;
    font-weight: 400;
  }

  .organization-avatar {
    width: $size26;
    height: $size26;
    border: 1px solid $border-color;
    border-radius: 3px;
    object-fit: cover;
    padding: 2px;
    text-align: center;
  }

  .icon {
    width: $size44;
  }

  .organization-name {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
}

.icon-btn {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: pointer;
  &--md {
    height: $size32;
    width: $size32;
  }
  &--primary {
    background-color: $primary;
    color: white;
  }

  &--watermelon {
    background-color: $watermelon;
    color: white;
  }

  &--white {
    background-color: $white;
    color: $primary;
  }
  &--secondary {
    background: $light-blue;
    color: $white;
  }
  &--green {
    background-color: $green-500;
    color: white;
  }
  &--disabled {
    background-color: $gray-400 !important;
  }
}

.line-height-24 {
  line-height: $size24;
}

.line-height-18 {
  line-height: $size18;
}

.periods {
  .picker {
    label {
      margin-right: $size8 !important;
    }
    .form-error {
      left: 0 !important;
    }
  }
  .SingleDatePicker {
    &:before {
      display: none;
    }
  }
  .SingleDatePicker_picker {
    left: -100px !important;
  }
}

.svg-click-handler {
  > .svg-wrap {
    width: 100%;
    pointer-events: none;

    > div {
      width: 100%;
      height: 100%;
      display: inline-flex;
    }
  }
}

.task-row {
  .rearrange-icon {
    left: -$size34;
    top: $size18;
  }
}
