.radio-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: $size24;
  min-height: $size20;
  min-width: $size20;
}

.radio-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 1px;
  height: $size18;
  width: $size18;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $gray-500;
}

.radio-wrapper:hover input ~ .checkmark {
  background-color: $gray-300;
}

.radio-wrapper input:checked ~ .checkmark {
  background-color: $blue;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-wrapper input:checked ~ .checkmark:after {
  display: block;
  background-color: $blue;
  border: 3px solid white;
}

.radio-wrapper .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size14;
  height: $size14;
  border-radius: 50%;
  background: white;
}
