.moms-table {
  .table-sticky {
    .table-mm {
      min-width: 1200px;

      @include media-breakpoint-up(xl) {
        min-width: 850px;
      }
    }
  }
}

.moms-comment-group {
  textarea.form-control {
    border: none !important;
    padding: 0 !important;
  }
}
