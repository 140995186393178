.doughnuts-container {
  width: 75%;

  @media (max-width: 1400px) {
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  &__dougnhuts {
    width: 80%;
    margin-bottom: $size32;

    @media (max-width: 1400px) {
      width: 100%;
    }
  }

  &__buttons {
    width: 20%;
    align-items: flex-end;
  }
}

.doughnut {
  width: 30%;
}

.arrivals-departures {
  &-empty {
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.9 24'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M13.19 18.7a.36.36 0 100 .72l.81.06c3.68.32 6.22 1.13 6.22 1.83s-4.67 2-9.72 2c-5.32 0-9.72-1-9.72-2s4.2-1.91 9.35-2v1.18a.38.38 0 00.37.37.37.37 0 00.36-.37V10a13.71 13.71 0 0010-4.69.37.37 0 00-.27-.61c-1.54 0-2.8-.58-4.8-2l-1.86-1.32-.44-.29c-.26-.16-.49-.27-.74-.42a5.08 5.08 0 00-2.3-.67.37.37 0 00-.37.36v18.26C4.43 18.67 0 19.72 0 21.31S4.62 24 10.45 24C16 24 20.9 22.8 20.9 21.31S17.77 19 13.19 18.7z' fill='%23EDEDED' data-name='Layer 1'/%3E%3C/g%3E%3C/svg%3E");
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: $size48;
    background-position: 0;

    &-text-container {
      position: absolute;
      top: 50%;
      left: 25%;
    }

    &-container {
      .pagination,
      .table-mm-inner {
        display: none !important;
      }
    }

    &-text {
      color: $info-text;
      font-size: $size14;
      font-weight: 600;
      opacity: 0.6;
    }

    &-subtext {
      color: $primary;
      font-size: $size10;
      font-weight: $font-weight-normal;
    }

    @include media-breakpoint-up(xl) {
      &-text {
        font-size: $size20;
      }
      &-subtext {
        font-size: $size14;
      }
    }
  }

  &-table {
    position: relative;

    .table-mm--search {
      position: relative;
      margin-bottom: $size160;
      top: -$size72;
    }

    .table-mm--search + .d-flex {
      position: relative;
      width: $size224;
      top: -14rem;
    }

    .days-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: auto;
      right: -15rem;
      margin-top: $size64;
      top: -$size16;
      z-index: 1;
    }

    .doughnuts-container__dougnhuts {
      margin-bottom: $size16;
      max-height: 192px;
      max-width: 976px;
    }
  }
}
