.magic-link {
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 24vh;
  background: #fff;
  width: 100%;

  .magic-link__wrap {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    background-color: transparent;
    padding: 0;
    height: auto;

    .email-text {
      font-size: $size12;
    }
  }

  .form-control {
    font-size: $size14;
    height: $size40;
    flex: 1;

    @include media-breakpoint-up(hd) {
      font-size: $size16;
    }
  }

  .inputs {
    flex: 1;
    flex-direction: row !important;
  }

  [type='submit'] {
    svg {
      width: auto !important;
    }
  }
}

.scan-instructions {
  height: 76vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;

  @include media-breakpoint-up(lg) {
    display: block;
    height: auto;
    padding-top: 0;
  }

  .step {
    flex-direction: row-reverse;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .svg-wrap {
    margin-left: $size26;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }
}

.scan-section {
  height: 76vh !important;
  padding-top: 20vh;

  @include media-breakpoint-up(lg) {
    padding-top: 15vh;
    height: 100% !important;
  }

  &__qr {
    margin-left: -$size26;

    @include media-breakpoint-up(lg) {
      margin-left: -2.5rem;
    }

    @include media-breakpoint-up(xl) {
      margin-left: -3.75rem;
    }
  }

  .qr-container {
    width: calc(100% - #{$size32});
    height: calc(100% - #{$size32});

    > #apparatus_qrcode,
    > #apparatus_qrcode img {
      width: 100%;
      height: 100%;
    }

    &::before {
      content: '';
      width: $size16;
      height: calc(100% - #{$size48});
      border-top: 5px solid $brainy-green;
      border-bottom: 5px solid$brainy-green;
      border-left: 5px solid $brainy-green;
      position: absolute;
      top: $size24;
      left: -$size16;

      @include media-breakpoint-up(lg) {
        right: -$size16;
        left: initial;
        border-left: none;
        border-right: 5px solid $brainy-green;
      }
    }

    &.is-loading {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background: url('~assets/svg/general/loader.svg') no-repeat center center;
        z-index: 2;
      }

      > #apparatus_qrcode {
        filter: blur(2px);
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          background: #fff;
          opacity: 0.2;
        }
      }
    }
  }

  .qr-box {
    z-index: 100;
    box-shadow: 0 10px 30px 0 rgba(55, 55, 55, 0.5);
    width: 16.5rem;
    height: 16.5rem;

    @include media-breakpoint-up(lg) {
      width: 12rem;
      height: 12rem;
    }

    @include media-breakpoint-up(lap) {
      width: 15rem;
      height: 15rem;
    }

    @include media-breakpoint-up(xxl) {
      width: 18rem;
      height: 18rem;
    }

    @include media-breakpoint-up(hd) {
      width: 20.25rem;
      height: 20.25rem;
    }

    .loader-wrapper {
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
    }
  }
}

.scan-instructions-number {
  font-size: $size20;
  align-self: flex-start;
  text-align: end;
  width: 16px;
  height: 30px;
  z-index: 10;
  position: relative;
  font-weight: normal;
  margin-left: $size36;

  @include media-breakpoint-up(hd) {
    margin-left: 0;
    margin-right: $size8;
  }

  @include media-breakpoint-up(hd) {
    font-size: $size24;
    margin-right: $size20;
  }

  &:before {
    content: '';
    position: absolute;
    height: 5px;
    width: 20px;
    z-index: -1;
    background-color: rgba(0, 211, 205, 0.2);
    top: 17px;
    left: -12px;
  }

  &.number-2 {
    left: -10px;

    &:before {
      left: 8px;
    }
  }
}

.scan-smartphone {
  svg {
    width: $size48;
    height: $size80 !important;

    @include media-breakpoint-up(hd) {
      width: $size64;
      height: $size112 !important;
    }
  }
}

.get-apparatus {
  margin-left: $size36;
  a {
    text-decoration: underline;
    color: $brainy-green;
  }

  svg {
    width: 95px;
    height: 28px !important;

    @include media-breakpoint-up(xl) {
      width: 120px;
      height: 40px !important;
    }

    @include media-breakpoint-up(hd) {
      width: 135px;
    }
  }
}
