.custom-multi-select {
  position: relative;
  /*  min-width: 12rem;
*/
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .toggle {
    position: absolute;
    right: 0;
    top: 0;
    width: $size16;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
  }

  .dropdown-is-open {
    .toggle {
      transform: rotate(180deg);
    }
  }

  .divider {
    position: absolute;
    right: $size24;
    top: 0;
    bottom: 0;
    margin: auto;
    height: calc(100% - 12px);
    width: 1px;
    background-color: $border-color;
  }

  .remove-all {
    position: absolute;
    right: $size24;
    top: 0;
    width: $size16;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .selected-options {
    min-height: $size32;
    border-bottom: 1px solid $input-border-color;

    padding: 0 $size32 0 0;
    position: relative;

    .label {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    li {
      display: inline-block;
      background-color: $border-color;
      padding: 0 $size16 0 $size8;
      margin: 4px 2px;
      cursor: pointer;
      font-size: $size12;
      position: relative;

      .remove-selected-option {
        position: absolute;
        top: calc(50% - #{$size8});
        right: 0;
        width: $size16;
        height: $size16;
        display: inline-flex;
        align-items: center;

        &:hover {
          color: $dark-red;
        }
      }
    }

    ul {
      padding-top: $size4;
    }
  }

  .select-all-option {
    padding: $size12 $size16;
    border-bottom: 1px solid $border-color;
  }

  .options-drodown {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    background-color: white;
    z-index: 5;
    box-shadow: 4px 6px 32px -3px $shadow;
    max-height: 250px;
    overflow: auto;

    .option {
      cursor: pointer;
      padding: $size4 $size16;

      &:hover {
        color: $light-blue;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2.5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $border-color;
      border-radius: 2.5px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 2.5px;
    }
  }

  &.options-top .options-drodown {
    top: initial;
    bottom: calc(100% + #{$size8});
  }

  li > ul .option {
    display: flex;
    align-items: baseline;

    &:before {
      content: '';
      min-width: 6px;
      min-height: 10px;
      display: inline-block;
      margin-right: 0.6rem;
      background: url('~assets/svg/general/chevron-right.svg') no-repeat;
    }
  }

  .label {
    white-space: nowrap;
    color: $placeholder;

    &--selected {
      color: black;
    }
  }

  .checkbox {
    .text {
      line-height: 14px;
    }
  }

  .selected {
    background-color: $selected-option;
  }
}
