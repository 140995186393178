.approvals {
  .form-group .form-control {
    height: calc(#{$size40} + #{$size2});
    width: calc(#{$size80} + #{$size8});
    background-color: $body-bg;
  }

  .approve-leave {
    background-color: rgba(216, 216, 216, 0.13);
    border: 1px solid rgba(151, 151, 151, 0.13);
  }

  .add-more {
    &:before {
      content: none;
    }
  }
}
