.leave-requests {
  .checkbox {
    margin-top: 2px;
  }
}

.modal-leave-request {
  max-width: 48rem;
}

.modal-cancel-request {
  max-width: $modal-md;

  .reason-tag {
    font-size: $size14;
    color: $booking-type-9;
  }

  .request-warning {
    max-width: $size224;
    color: $red-400;
  }
}
.cancel-leave-wrapper {
  cursor: pointer;
  :hover {
    color: $red-400;
  }
}
