.legal-page {
  margin-top: $size88;
  height: calc(100vh - #{$size88});
  overflow: hidden;

  @include media-breakpoint-up(md) {
    overflow: initial;
    margin-top: $size112;
    height: calc(100vh - #{$size112});
  }

  .legal-sidebar {
    .legal-sidebar-item {
      position: relative;
      margin-bottom: 40px;
      color: $gray-350;
      font-size: $size16;
      font-weight: bold;
      line-height: 20px;

      .blub {
        display: none !important;
        top: 0.25rem;
        left: 28%;
        color: $brainy-green;
      }

      &:hover,
      &.active {
        color: inherit;
        .blub {
          display: block !important;
        }
      }
    }
  }

  .legal-content {
    position: relative;
    height: 100%;

    .legal-logo {
      position: absolute;
      top: -115px;
      left: -53px;
      z-index: 100;
    }

    .legal-content-box {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      font-weight: 400;

      @include media-breakpoint-up(md) {
        border: 1px solid $gray-350;
      }

      &.scrollbar {
        position: relative;

        &::-webkit-scrollbar-thumb {
          background-color: $brainy-green;
        }
      }
    }

    .legal-content-box-fade {
      position: absolute;
      height: $size48;
      left: 1px;
      z-index: 1;
      bottom: $size64;
      width: calc(100% - 8px);
      background: linear-gradient(rgba(220, 212, 205, 0) 0%, #ffffff 100%);

      @include media-breakpoint-up(md) {
        bottom: calc(#{$size64} + 1px);
      }
    }
  }
}

.cookies-banner {
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  box-shadow: 0 -4px 18px 0 rgba(0, 0, 0, 0.05);

  a {
    color: $brainy-green;
    text-decoration: underline;
  }
}

.legal-default-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $size14;
    font-weight: bold;
    margin-bottom: $size16;

    &:not(:first-of-type) {
      margin-top: $size24;
    }

    @include media-breakpoint-up(md) {
      font-size: $size20;
      margin-bottom: $size24;

      &:not(:first-of-type) {
        margin-top: $size48;
      }
    }
  }

  p,
  ul,
  ol {
    font-size: $size12;
    margin-bottom: $size16;
    font-weight: normal;

    @include media-breakpoint-up(md) {
      font-size: $size16;
      margin-bottom: $size24;
    }
  }

  ul {
    li {
      display: flex;
      align-items: flex-start;

      &:before {
        content: '-';
        display: block;
        height: 100%;
        padding-right: $size8;
      }
    }
  }

  a {
    color: $brainy-green !important;
  }
}
