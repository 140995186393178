.slider-picker {
  > div {
    &:last-child {
      > div > div {
        width: 50% !important;

        > div {
          height: $size10 !important;
          border-radius: 1px !important;
          transition: all 0.2s !important;
          transform: scaleY(1) !important;
          &.selected {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            height: $size10 !important;
            transform: scaleY(1.6) !important;
          }
        }
        &:nth-of-type(n + 3) {
          display: none;
        }

        &:first-child {
          padding-right: $size4 !important;
        }
      }
    }
  }
}
