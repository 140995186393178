body {
  font-size: $size14;

  @include media-breakpoint-up(lap) {
    font-size: $font-size-base;
  }
}

.container-xl {
  @include make-container-max-widths($container-xl-max-widths);
}

.container-sm {
  @include make-container-max-widths($container-sm-max-widths);
}
