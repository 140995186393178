.clock-timepicker--popover {
  width: 19rem;
  padding: 0;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;

  .arrow {
    display: block;

    &::before {
      border-bottom-color: $border-color;
    }
  }

  .popover-body {
    padding: 0;
  }

  .react-timekeeper {
    width: 100% !important;
    box-shadow: none !important;
    font-family: $font-family-base;

    > div:not(.react-timekeeper__clock-wrapper) {
      padding: $size16 $size16 $size8 $size16 !important;
      width: $size160;

      span {
        font-weight: 600;
        font-size: $size28 !important;
      }

      .react-timekeeper-button-reset {
        color: #3c4858 !important;
        font-size: $size10 !important;
        opacity: 0.3;
        display: none !important;
      }
    }

    .react-timekeeper__clock-wrapper {
      padding: $size16 !important;

      .react-timekeeper__meridiem-toggle-wrapper {
        margin-top: $size16 !important;
        position: initial !important;

        .react-timekeeper-button-reset {
          position: absolute;
          top: $size20;
          left: $size168;
          font-size: $size10 !important;
          background: none !important;
          width: $size16 !important;
          height: $size16 !important;
          line-height: $size16 !important;

          &.type_pm {
            top: $size32;
          }
        }
      }

      .react-timekeeper__clock-animations {
        > span {
          font-size: $size14 !important;
          font-weight: 300;
        }
      }

      + span {
        background: $light-blue;
        font-weight: 400 !important;
        font-size: $size14 !important;
        margin-top: $size8;
      }
    }
  }
}
