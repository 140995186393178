.control-panel {
  .table th,
  .table td {
    vertical-align: middle;

    .svg-wrap {
      justify-content: center;
    }
  }

  .table {
    width: calc(100% + 0.5px);
  }

  .download-pdf-row {
    td {
      border-top: 0;
      background-color: white;
    }
    .svg-wrap {
      justify-content: center;
    }
  }

  .download-pdf {
    position: relative;

    .svg-wrap {
      height: 16px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .status-row {
    background-color: $page-background;
    border: 1px solid $page-background;

    td {
      padding: 16px 12px;
      background-color: $page-background;
    }
  }
}

.panel {
  .select-year {
    background-color: white;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0;
    background-color: white;

    th {
      background-color: white;
      border-top-color: $border-color;
    }
  }
}

.select-year {
  width: 100%;
  border: 1px solid $border-color;
  border-bottom: 0;
}
