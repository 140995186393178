.inner-page-modal {
  .modal-dialog {
    max-width: 100%;
  }

  .modal-body,
  .modal-content {
    padding: 0 !important;
    overflow: visible !important;
    background: none !important;
    border: 0 !important;
    display: block !important;
  }

  &-close {
    position: absolute;
    top: -$size44;
    right: 0;
    cursor: pointer;
    font-weight: $font-weight-bold;
    font-size: $size18;
    display: flex;
    margin-right: $size8;

    svg {
      margin-left: $size8;
    }
  }

  &-backdrop {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

body.modal-open {
  header,
  .footer-wrapper,
  .inner-page-section,
  .brainy-inner-intro {
    animation: blur 0.3s ease-in-out forwards;
  }
}

body.modal-closing {
  header,
  .footer-wrapper,
  .inner-page-section,
  .brainy-inner-intro {
    animation: remove_blur 0.2s ease-in-out forwards;
  }
}

@keyframes blur {
  from {
    filter: blur(0px);
  }
  to {
    filter: blur(10px);
  }
}

@keyframes remove_blur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
}

.modal {
  .getstarted {
    background-color: $gray-350;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 10px 20px 40px 0 rgba(0, 0, 0, 0.1);

    h3 {
      font-size: $size16;
      line-height: $size24;
      font-weight: $font-weight-bold;
      margin: 0 !important;

      @include media-breakpoint-up(md) {
        font-size: $size18;
      }

      @include media-breakpoint-up(xl) {
        font-size: $size26;
      }

      @media only screen and (max-width: map-get($grid-breakpoints, xl)) and (orientation: landscape) {
        font-size: $size20;
      }
    }
  }

  .back {
    box-shadow: 10px 20px 40px 0 rgba(0, 0, 0, 0.1);
    background-color: $gray-350;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 120px;

    &-bg {
      background: url('~assets/svg/public/blubs/pattern_green.svg') repeat center;
      opacity: 0.5;
      height: 100%;
      width: 100%;
    }
  }
}

.inner-page-section {
  .hr-text-section {
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 5%;
    }
  }

  .orders-text-section {
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-bottom: 13%;
    }
  }

  h3 {
    z-index: 2;
    font-weight: $font-weight-bold;
    color: $public-text;
    font-size: $size16;
    line-height: $size20;

    @include media-breakpoint-up(md) {
      font-size: $size18;
      line-height: $size24;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size28;
      line-height: $size36;
    }
  }

  h4 {
    z-index: 2;
    font-weight: $font-weight-normal;
    color: $brainy-green;
    font-size: $size16;
    line-height: $size6;

    @include media-breakpoint-up(md) {
      font-size: $size18;
      line-height: $size10;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size28;
      line-height: $size22;
    }
  }

  .accoma-text {
    z-index: 2;
    font-weight: $font-weight-bold;
    color: $accoma;
    font-size: $size16;
    line-height: $size6;

    @include media-breakpoint-up(md) {
      font-size: $size18;
      line-height: $size10;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size28;
      line-height: $size22;
    }
  }

  p {
    z-index: 2;
    font-weight: $font-weight-normal;
    color: $public-text;
    font-size: $size16;
    line-height: $size24;
    max-height: 100%;
    overflow: visible;

    @include media-breakpoint-up(md) {
      max-height: $size120;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size20;
      line-height: $size32;
      max-height: 100%;
    }
  }

  span {
    z-index: 2;
    font-weight: $font-weight-normal;
    color: $public-text;
    font-size: $size12;
    line-height: $size16;

    @include media-breakpoint-up(md) {
      font-size: $size14;
      line-height: $size18;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size20;
      line-height: $size32;
    }
  }

  .icon-tooltip {
    z-index: 1;
    position: absolute;
    color: $red-200;
    font-weight: $font-weight-bold;
    border: 1px solid $red-200;
    border-radius: 3px;
    padding: $size2 $size4;
    font-size: $size8;
    line-height: $size10;
    margin-left: $size80;
    top: -$size20;

    @include media-breakpoint-up(xl) {
      padding: $size4 $size8;
      font-size: $size12;
      line-height: $size16;
      margin-left: $size112;
      top: -$size32;
    }
  }

  img {
    filter: drop-shadow(10px 40px 60px rgba(0, 0, 0, 0.1));
  }
}

.inner-page-images {
  &--reservation-wizard {
    padding-top: 20%;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }

    img:nth-of-type(1) {
      width: 95%;
      margin-left: 2%;

      @include media-breakpoint-up(md) {
        width: 80%;
        margin-left: 2%;
      }
    }

    img:not(:first-child) {
      position: absolute;
    }

    img:nth-of-type(2) {
      right: 0;
      top: 0;
      width: 35%;

      @include media-breakpoint-up(md) {
        top: 10%;
        width: 30%;
      }
    }

    img:nth-of-type(3) {
      left: 0;
      top: 16%;
      width: 30%;
    }

    img:nth-of-type(4) {
      right: 0;
      top: 90%;
      width: 35%;

      @include media-breakpoint-up(md) {
        width: 30%;
        top: 85%;
      }
    }
  }

  &--calendar-overview {
    padding-bottom: 6%;

    img:nth-of-type(1) {
      width: 96%;
      margin-left: 4%;
    }

    img:not(:first-child) {
      position: absolute;
    }

    img:nth-of-type(2) {
      left: 0;
      top: 53%;
      width: 32%;
    }

    img:nth-of-type(3) {
      right: 10%;
      bottom: 0;
      width: 23%;
    }
  }

  &--leaves-overview {
    padding-bottom: 6%;
    padding-top: 13%;

    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 10%;
    }

    img:nth-of-type(1) {
      width: 95%;

      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    img:not(:first-child) {
      position: absolute;
    }

    img:nth-of-type(2) {
      width: 25%;
      top: 0;
      left: 10%;

      @include media-breakpoint-up(md) {
        top: initial;
        left: initial;
        right: 5%;
        bottom: 0;
      }
    }

    img:nth-of-type(3) {
      width: 35%;
      right: 0;
      bottom: -42.5%;

      @include media-breakpoint-up(md) {
        left: -24%;
      }
    }
  }

  &--shifts-overview {
    padding-bottom: 6%;

    img:nth-of-type(1) {
      width: 100%;
    }

    img:nth-of-type(2) {
      position: absolute;
      left: 20%;
      bottom: 0;
      width: 25%;
    }
  }

  &--orders {
    padding-bottom: 50%;
    padding-top: 10%;

    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 26%;
    }

    img:nth-of-type(1) {
      width: 98%;

      @include media-breakpoint-up(md) {
        width: 90%;
      }
    }

    img:not(:first-child) {
      position: absolute;
    }

    img:nth-of-type(2) {
      left: 9%;
      bottom: 0;
      width: 47%;

      @include media-breakpoint-up(md) {
        left: 18.5%;
        width: 34%;
      }
    }

    img:nth-of-type(3) {
      right: 0;
      top: 0;
      width: 27%;

      @include media-breakpoint-up(md) {
        top: 22%;
      }
    }
  }

  &--menus {
    padding-bottom: 27%;
    padding-top: 10%;

    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 15%;
    }

    img:nth-of-type(1) {
      width: 95%;

      @include media-breakpoint-up(md) {
        width: 86%;
      }
    }

    img:not(:first-child) {
      position: absolute;
    }

    img:nth-of-type(2) {
      left: 20%;
      bottom: 0;
      width: 44%;

      @include media-breakpoint-up(md) {
        left: 28.5%;
        width: 30%;
      }
    }

    img:nth-of-type(3) {
      right: 0;
      top: 0;
      width: 30%;

      @include media-breakpoint-up(md) {
        top: 33%;
        width: 22%;
      }
    }
  }

  &--entrances {
    padding-bottom: 5%;
    margin-left: 4%;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      margin-left: 0;
    }

    img:nth-of-type(1) {
      width: 96%;

      @include media-breakpoint-up(md) {
        width: 92%;
      }
    }

    img:nth-of-type(2) {
      position: absolute;
      right: 0;
      width: 30%;
      bottom: 0;

      @include media-breakpoint-up(md) {
        top: 28%;
      }
    }
  }

  &--inandout {
    padding-left: 0;
    margin-left: 3%;

    @include media-breakpoint-up(md) {
      padding-left: 6%;
      margin-left: 0;
    }

    img:nth-of-type(1) {
      width: 96%;

      @include media-breakpoint-up(md) {
        width: 92%;
      }
    }

    img:nth-of-type(2) {
      position: absolute;
      right: 0;
      width: 59%;
      bottom: 15%;

      @include media-breakpoint-up(md) {
        top: 11%;
        width: 40%;
        left: 0;
      }
    }
  }

  &--accoma {
    img:nth-of-type(3) {
      position: absolute;
      opacity: 0.6;
      filter: drop-shadow(0 2px 50px rgba(254, 221, 0, 0.5));
      left: -150px;
      height: 277px;
      width: 250px;

      @include media-breakpoint-up(md) {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        filter: initial;
        display: block;
        width: 363px;
        height: 400px;
      }
    }

    img:not(:nth-of-type(3)) {
      z-index: 1;
    }
  }

  &--mobile {
    &-booking {
      img:nth-of-type(1) {
        width: 85%;

        @include media-breakpoint-up(md) {
          width: 70%;
        }
      }

      img:nth-of-type(2) {
        position: absolute;
        right: 0;
        top: 20%;
        width: 50%;
      }
    }

    &-create-booking {
      display: flex;
      flex-direction: column;

      img:nth-of-type(1) {
        margin-left: 0;
        width: 80%;
        margin-bottom: -16%;

        @include media-breakpoint-up(md) {
          margin-left: auto;
        }
      }
      img:nth-of-type(2) {
        margin-left: 44%;
        bottom: 23%;
        width: 50%;

        @include media-breakpoint-up(md) {
          margin-left: 8%;
        }
      }
      img:nth-of-type(3) {
        margin-left: 14%;
        width: 50%;

        @include media-breakpoint-up(md) {
          margin-left: 34%;
        }
      }
    }

    &-leaves {
      img:nth-of-type(1) {
        width: 85%;
        z-index: 3;

        @include media-breakpoint-up(md) {
          width: 70%;
        }
      }

      img:nth-of-type(2) {
        position: absolute;
        right: 0;
        bottom: 23%;
        width: 50%;
        z-index: 3;
      }

      div {
        position: absolute;
        top: $size64;
        left: -250px;
      }
    }
  }
}

.accoma-button {
  padding: $size8 $size16;
  display: none;
  color: $accoma !important;
  font-size: $size14;
  font-weight: $font-weight-bold;
  border-radius: 3px;
  box-shadow: 2px 2px 8px rgba(26, 20, 35, 0.1);
  width: fit-content;
  transition: 0.2s ease-in-out;

  svg {
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background-color: #fffef7; // Same as text color in 3% opacity
    transition: 0.2s ease-in-out;
    box-shadow: none !important;

    svg {
      transition: 0.2s ease-in-out;
      transform: translate(4px);
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }
  @include media-breakpoint-up(xl) {
    font-size: $size18;
    padding: $size14 $size24;
  }

  .svg-wrap {
    margin-left: $size40;
  }
}

.inner-page-wave {
  position: absolute;
  z-index: 0;
  height: 10000px;
  width: 100vw;
  background: linear-gradient(125deg, #d8dbe2 0%, #f3f6ff 100%);
  opacity: 0.4;

  &-mask {
    background-image: url('~assets/svg/public/inner_pages/waves/mobile.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 1600px;
    top: -850px;
    position: absolute;

    @include media-breakpoint-up(md) {
      background-image: url('~assets/svg/public/inner_pages/waves/tablet.svg');
      background-size: 100% 1000px;
      height: 1000px;
    }

    @include media-breakpoint-up(xl) {
      background-image: url('~assets/svg/public/inner_pages/waves/desktop.svg');
      background-size: 100% 1200px;
      height: 1200px;
      top: -350px;
    }

    &.mobile-app {
      transform: initial;

      @include media-breakpoint-up(md) {
        transform: scaleX(-1);
        top: -300px;
      }
    }

    &.invite-and-book {
      @include media-breakpoint-up(md) {
        top: -300px;
      }
    }

    &.hr {
      @include media-breakpoint-up(md) {
        top: -280px;
      }
    }

    &.orders {
      @include media-breakpoint-up(md) {
        top: -280px;
      }

      @include media-breakpoint-up(xl) {
        top: -250px;
      }
    }

    &.in-and-out {
      @include media-breakpoint-up(md) {
        top: -350px;
      }
    }
  }
}

.inner-page-blob {
  position: absolute;
  width: 88px;
  height: 92px;
  top: -$size18;
  filter: drop-shadow(0px 1px 50px $red-200) !important;

  &.rotated {
    transform: rotate(90deg);
    top: -$size24;
  }
}

.inner-page-title-blob {
  position: absolute;
  width: 95px;
  height: 120px;
  top: -$size32;
  left: -$size40;

  &--orders {
    top: -$size40;
    left: -$size48;
  }
}

.paragraph-with-little-blob {
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    background: url('~assets/svg/public/blubs/header_blub.svg') no-repeat center;
    height: 48px;
    width: 30px;
    display: block;
    background-size: contain;
    position: absolute;
    top: -$size8;
    left: -$size10;
    z-index: 0;
  }
}

.inner-page-wave-blob {
  position: absolute;
  z-index: 2;
  filter: drop-shadow(0 2px 24px #ffe1e1);
  top: 29%;
  left: 2%;
  width: 64px;
  height: 64px;

  @include media-breakpoint-up(xl) {
    left: 10%;
    width: 92px;
    height: 92px;
  }

  &--inviteandbook {
    top: 37%;
  }

  &--mobile {
    left: initial;
    top: 29%;
    right: 2%;

    @include media-breakpoint-up(lg) {
      top: 27.5%;
    }

    @include media-breakpoint-up(xl) {
      top: 27%;
      right: 8%;
    }

    @include media-breakpoint-up(hd) {
      top: 26%;
    }
  }
}

.btn-login {
  width: $size112;
  @include media-breakpoint-up(sm) {
    width: $size128;
  }
  @include media-breakpoint-up(xl) {
    width: auto;
  }
}
