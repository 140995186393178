.split-layout {
  height: 100vh;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .main-content {
    flex: 1;
  }

  .right-section {
    background-color: $gray-350;

    .logo {
      position: absolute;
      left: -$size112;
      top: $size24;
    }
  }
}

.login-grouped-text {
  border-left: 3px solid $brainy-green;
  line-height: $size16;
  font-size: $size14;
  padding-left: $size16;

  > div {
    + div {
      margin-top: $size12;
    }
  }
}

.mobile-app-message {
  background-color: $gray-350;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  h3 {
    font-size: $size26;
    line-height: $size40;
    font-weight: 400;
    z-index: 1;
    color: $public-text;
    position: relative;

    .blub-title {
      position: absolute;
      top: $size2;
      left: $size56;
      z-index: -1;
      width: $size40;
    }

    + span {
      line-height: $size28;
      color: $public-text;
    }
  }

  .images {
    justify-content: space-evenly;
    margin-top: $size64;

    a {
      text-decoration: underline;
      color: $brainy-green;
    }

    span {
      font-size: $size16;
    }

    svg {
      width: 135px;
      height: 40px !important;
    }
  }

  @media only screen and (max-device-width: map-get($grid-breakpoints, md)) and (orientation: landscape) {
    flex-direction: column;
    justify-content: center !important;

    > div {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
}

.brainy-logout {
  position: fixed;
  top: $size48;
  right: $size24;
}
