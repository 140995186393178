.btn {
  box-shadow: none !important;
  cursor: pointer;

  &:disabled,
  &.disabled {
    cursor: initial;
  }

  &.btn-secondary-link {
    background: none !important;
    color: $light-blue;

    &:hover {
      color: darken($light-blue, 15%);
    }

    &:disabled {
      color: $calendar-border-color;
    }
  }

  &.btn-outline-apparatus {
    background: none !important;
    color: $apparatus !important;
  }

  &.btn-danger-action {
    color: $light-red;
  }

  &.btn-danger,
  &.btn-orange-secondary {
    color: #fff;
  }

  &.btn-add-new {
    background: none;
    border: 1px dashed $primary;
    font-size: $size14;
    line-height: 22px;
    transition: all 0.15s ease-in-out;

    &:hover {
      border: 1px solid $primary;
    }
  }

  &.btn-arrow {
    background-color: transparent;
    padding: 0;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    &:before {
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px;
      background: url('~assets/svg/general/calendar_arrow.svg') no-repeat center;
      background-size: contain;
      transition: all 200ms ease-in-out;
      transform: rotate(0);
    }

    &.open {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  &.btn-small {
    font-size: $size14;
    padding: $size4 $size16;
    line-height: 22px;

    &.btn-smaller {
      padding: $size2 $size16;
    }
  }

  &.btn-cancel,
  &.btn-add {
    padding: 0;
    background: none !important;
    border: none !important;
    color: $light-blue;
    line-height: 1;
    @extend .btn-outline-cancel;

    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      color: $light-blue;
    }

    &:hover {
      color: darken($light-blue, 15%);
    }
  }

  &.btn-add,
  &.btn-remove {
    position: relative;
    padding-left: 14px;

    &::before {
      position: absolute;
      content: '+';
      left: 0;
      font-size: 18px;
      display: inline-block;
      height: 18px;
      top: -3px;
    }
  }

  &.btn-group-arrow {
    height: $size28;

    .svg-wrap {
      transform: rotate(-90deg);
    }

    &:hover {
      color: $light-blue;
    }

    // &:hover,
    &.active {
      background: lighten($light-blue, 30%);
    }
  }

  &.btn-remove,
  &.btn-decline {
    padding: 0;
    padding-left: 14px;
    background: none !important;
    border: none !important;
    color: $light-red;
    font-weight: 300;
    min-width: $size16;
    min-height: $size18;

    &:hover {
      color: $dark-red;
    }

    &::before {
      top: 0px;
      font-weight: bold;
      transform: rotate(45deg);
    }
  }

  &.btn-sm {
    height: $size32;
    line-height: 30px;
    padding: 0 $size24;
    font-size: $size14;
    border-radius: 1px;
  }

  &.btn-outline-disabled {
    border-color: $info-text;
    background: none !important;
    color: $info-text;
  }

  &.btn-outline-secondary {
    &.inactive:not(:hover) {
      color: $info-text;
      border-color: $calendar-border-color;
    }
  }

  &.btn-options {
    &:hover {
      color: $white !important;
    }

    &.inactive:not(:hover) {
      &:not(:hover) {
        color: $info-text !important;
        border-color: $calendar-border-color;
        background: none;
      }
    }
  }

  &.btn-plugin-enabled,
  &.btn-plugin-disabled {
    min-width: $size136;
    font-size: $size12;
    padding: 0 $size16;
    border: 1px solid;
    position: relative;

    &::before {
      content: '\D7';
      font-size: $size18;
      font-weight: 400;
      line-height: 17px;
      position: absolute;
      width: $size20;
      height: $size20;
      border-radius: 100%;
      background: #fff;
      border: 1px solid;
      top: calc(50% - #{$size10});
      left: -$size10;
    }
  }

  &.btn-plugin-enabled {
    color: $light-blue;

    &:hover {
      color: darken($light-blue, 15%);
    }

    &::before {
      transform: rotate(45deg);
    }
  }

  &.btn-plugin-disabled {
    color: $red-400;

    &:hover {
      color: darken($red-400, 15%);
    }
  }

  &.btn-white {
    background: $white;
    border: none;
    color: $brainy-green;
  }

  &.btn-outline-brainy,
  &.btn-brainy {
    border-width: 2px;
    border-radius: 3px;
    font-weight: bold;
  }

  &.btn-blue-link {
    color: $light-blue;

    &:hover {
      color: darken($light-blue, 15%);
    }
  }
  &.btn-time-picker {
    line-height: 14px;
  }

  &.btn-popover {
    &:hover {
      position: relative;
    }
  }

  &.btn-activate,
  &.btn-deactivate,
  &.btn-outline-deactivate:hover {
    color: #fff;
  }

  &.success {
    &:hover {
      color: $green-500;
    }
  }
  &.danger {
    &:hover {
      color: $light-red;
    }
  }

  &.pending {
    &:hover {
      color: $orange-500;
    }
  }
}

.btn-232 {
  display: block;
  max-width: 232px;
  padding: $size8 $size16;
  border: 1px dotted $dark-red;
  font-size: $size14;
  cursor: pointer;
  background-color: white;
}

.button {
  @extend .btn;
}

.small-btn {
  display: inline-block;
  color: white;
  font-size: 1rem;
  padding: 4px 16px;
  cursor: pointer;
  text-align: center;
}

.match-spaces-button {
  height: $size26;
  width: $size26;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 0;

  &--send {
    background: $light-blue;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
  }
  &--delete {
    background: $red-400;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
  }
  &--accept {
    background: $primary;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3) !important;
  }
}
