.time-management {
  .form-group .form-control {
    height: calc(#{$size40} + #{$size2});
    width: calc(#{$size112} - #{$size2});
  }
}

.accrual-settings {
  .form-group .react-select__menu-list {
    padding: 0 $size12;
  }
}

.working-schedule {
  .select__menu-list {
    padding: 0;
  }

  .react-select {
    .react-select__menu {
      .react-select__menu-list {
        padding: $size8 $size10;
      }
    }
  }
}
