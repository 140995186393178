.leave-type-form {
  .icons-select {
    width: $size208;
  }

  .react-select__menu-list {
    display: flex;
    flex-wrap: wrap;

    .icons-select-option {
      @extend .col-3;
      @extend .justify-content-center;
      @extend .d-flex;
      cursor: pointer;
      margin: $size4 0;
    }
  }

  .icons-select .react-select__value-container {
    justify-content: center;
  }
}

.working-week {
  strong {
    font-weight: 400;
  }
}

.leaves {
  .not-allowed {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.department-form,
.shift-form {
  .form-group .color-select .form-error {
    left: 0 !important;
  }
}

.shift-screen {
  .desc-text-color {
    color: $light-blue;
  }
}

.shift-form {
  .text-right {
    margin-top: $size4;
  }

  .text-area .form-control {
    height: $size64 !important;
  }
}
